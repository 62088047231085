import React from "react";
import styles from "./ConfirmModal.module.css";

const ConfirmModal = ({ message, onConfirm, onCancel, icon }) => {
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        {icon && <div className={styles.icon}>{icon}</div>}
        <p> {message}</p>
        <div className={styles.buttonContainer}>
          <button onClick={onConfirm}>Yes</button>
          <button onClick={onCancel}>No</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
