import React, { useState } from "react";
import StatusSelect from "../../UI/StatusSelect/StatusSelect";
import { EditIcon, DeleteIcon, InfoIcon } from "../../assets/svgs";
import { TimestampConverter } from "../../Utils/TimestampConverter";
import { useTranslation } from "react-i18next";
import OrderSvg from "../../assets/svgs/OrderSvg";
import { Switches } from "../../UI/Switches/Switches";
import { Button } from "../../UI/Button/Button";
import ConfirmModal from "../../UI/ConfirmModal/ConfirmModal";

const CustomersTable = ({
  customersData,
  th,
  mobile,
  mobileExpand,
  fillPopupDataHendler,
  deleteCustomer,
  changeCustomerStatus,
  setEdit,
  setPopupShow,
  setPopUpData,
  toggleCustomerBotStatus,
}) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const handleBotToggle = (customer) => {
    toggleCustomerBotStatus(customer._id, !customer.bot_suspended);
  };

  let statuses = [
    { name: t("statuses.approved"), value: "approved" },
    { name: t("statuses.pending"), value: "pending" },
    { name: t("statuses.canceled"), value: "canceled" },
  ];

  const renderTableCell = (content, width, isMobileWidth) => (
    <div
      className={`td ${isMobileWidth ? "mobile-width" : ""}`}
      style={{ width: `${width}%` }}
    >
      <span>{content}</span>
    </div>
  );

  const handleDeleteClick = (userId) => {
    setUserIdToDelete(userId);
    setShowModal(true);
  };

  const handleEditClick = (item) => {
    fillPopupDataHendler(item);
    setPopupShow("Edit Customer");
    setEdit(true);
  };

  const confirmDelete = () => {
    if (userIdToDelete) {
      deleteCustomer(userIdToDelete);
      setShowModal(false);
      setUserIdToDelete(null);
    }
  };

  const cancelDelete = () => {
    setShowModal(false);
    setUserIdToDelete(null);
  };

  return (
    <>
      {customersData?.map((item, index) => (
        <div
          key={index}
          className={`table-parent ${mobileExpand === index ? "active" : ""}`}
          onClick={() => {
            fillPopupDataHendler(item);
            setPopupShow("Customer Details");
          }}
        >
          <div className="table">
            {renderTableCell(
              item?.full_name,
              mobile ? th[0].mobileWidth : th[0].width,
              th[0].mobileWidth
            )}
            {renderTableCell(
              item?.gender,
              mobile ? th[1].mobileWidth : th[1].width,
              th[1].mobileWidth
            )}
            {renderTableCell(
              `${item?.phone_number?.code} ${item?.phone_number?.number}`,
              mobile ? th[2].mobileWidth : th[2].width,
              th[2].mobileWidth
            )}
            {renderTableCell(
              item?.userDetails?.username,
              mobile ? th[3].mobileWidth : th[3].width,
              th[3].mobileWidth
            )}
            <div
              className={`td ${th[4].mobileWidth ? "mobile-width" : ""}`}
              style={{ width: `${mobile ? th[4].mobileWidth : th[4].width}%` }}
              onClick={(e) => e.stopPropagation()}
            >
              <StatusSelect
                value={item.status}
                selectHandler={(e, id) =>
                  changeCustomerStatus(id, e.target.value)
                }
                itemId={item._id}
                statuses={statuses}
              />
            </div>
            <div
              className={`td ${th[5].mobileWidth ? "mobile-width" : ""}`}
              style={{
                width: `${mobile ? th[5].mobileWidth : th[5].width}%`,
                gap: "5px",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <Switches
                value={!item.bot_suspended || ""}
                onChange={() => handleBotToggle(item)}
              />
              <span>{`${item.bot_suspended ? "Off" : "On"}`}</span>
            </div>
            <div
              className={`td ${th[6].mobileWidth ? "mobile-width" : ""}`}
              style={{ width: `${mobile ? th[6].mobileWidth : th[6].width}%` }}
              onClick={(e) => e.stopPropagation()}
            >
              <Button
                label={t("customer.Order")}
                type={"btn-inTable"}
                element={"button"}
                onClick={() => {
                  setPopupShow("Make Order");
                  setPopUpData((prev) => ({
                    ...prev,
                    customer_id: item._id,
                    full_name: item.full_name,
                    phone_number: item.phone_number,
                    national_ID_number: item.national_ID_number,
                  }));
                }}
              />
            </div>
            <div
              className={`td ${th[7].mobileWidth ? "mobile-width" : ""}`}
              style={{
                width: `${mobile ? th[7].mobileWidth : th[7].width}%`,
                gap: "10px",
                padding: "10px",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <EditIcon onClick={() => handleEditClick(item)} />
              <DeleteIcon onClick={() => handleDeleteClick(item._id)} />
            </div>
          </div>
        </div>
      ))}
      {showModal && (
        <ConfirmModal
          message="Are you sure you want to delete this user?"
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
          icon={<DeleteIcon />}
        />
      )}
    </>
  );
};

export default CustomersTable;
