import React from "react";

const AddUserSvg = () => {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.45 10.5502V7.3752H15.275V5.7252H18.45V2.5502H20.125V5.7252H23.275V7.3752H20.125V10.5502H18.45ZM9.07495 8.8002C7.85828 8.8002 6.86662 8.41686 6.09995 7.6502C5.33328 6.88353 4.94995 5.9002 4.94995 4.7002C4.94995 3.48353 5.33328 2.49186 6.09995 1.7252C6.86662 0.958529 7.85828 0.575195 9.07495 0.575195C10.275 0.575195 11.2583 0.958529 12.025 1.7252C12.7916 2.49186 13.175 3.48353 13.175 4.7002C13.175 5.9002 12.7916 6.88353 12.025 7.6502C11.2583 8.41686 10.275 8.8002 9.07495 8.8002ZM0.699951 17.5252V14.8002C0.699951 14.1502 0.870784 13.5627 1.21245 13.0377C1.55412 12.5127 2.01662 12.1169 2.59995 11.8502C3.86662 11.2835 5.00412 10.8877 6.01245 10.6627C7.02078 10.4377 8.04162 10.3252 9.07495 10.3252C10.1083 10.3252 11.125 10.4377 12.125 10.6627C13.125 10.8877 14.25 11.2835 15.5 11.8502C16.1 12.1169 16.5708 12.5085 16.9125 13.0252C17.2541 13.5419 17.4249 14.1335 17.4249 14.8002V17.5252H0.699951ZM2.69995 15.5502H15.45V14.8502C15.45 14.6002 15.3791 14.3544 15.2375 14.1127C15.0958 13.871 14.8916 13.7002 14.625 13.6002C13.4916 13.0502 12.525 12.696 11.725 12.5377C10.925 12.3794 10.0416 12.3002 9.07495 12.3002C8.10828 12.3002 7.22078 12.3835 6.41245 12.5502C5.60412 12.7169 4.63328 13.0669 3.49995 13.6002C3.23328 13.7002 3.03328 13.871 2.89995 14.1127C2.76662 14.3544 2.69995 14.6002 2.69995 14.8502V15.5502ZM9.07495 6.8002C9.69162 6.8002 10.2 6.6002 10.6 6.2002C11 5.8002 11.2 5.3002 11.2 4.7002C11.2 4.06686 11 3.55436 10.6 3.1627C10.2 2.77103 9.69162 2.5752 9.07495 2.5752C8.45828 2.5752 7.94995 2.77103 7.54995 3.1627C7.14995 3.55436 6.94995 4.05853 6.94995 4.6752C6.94995 5.29186 7.14578 5.8002 7.53745 6.2002C7.92912 6.6002 8.44162 6.8002 9.07495 6.8002Z"
        fill="white"
      />
    </svg>
  );
};

export default AddUserSvg;
