import React from "react";

const PluseSvg = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 15.5H0V12.5H12.5V0H15.5V12.5H28V15.5H15.5V28H12.5V15.5Z"
        fill="#3E54AC"
      />
    </svg>
  );
};

export default PluseSvg;
