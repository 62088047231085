import React from "react";

const InvoiceSvg2 = () => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 13H9V11.5H3V13ZM3 10H9V8.5H3V10ZM1.49417 16C1.08139 16 0.729167 15.8531 0.4375 15.5594C0.145833 15.2656 0 14.9125 0 14.5V1.5C0 1.0875 0.146875 0.734376 0.440625 0.440626C0.734375 0.146876 1.0875 0 1.5 0H8L12 4V14.5C12 14.9125 11.8531 15.2656 11.5592 15.5594C11.2653 15.8531 10.9119 16 10.4992 16H1.49417ZM7 5V1.5H1.5V14.5H10.5V5H7Z"
        fill="#48752C"
      />
    </svg>
  );
};

export default InvoiceSvg2;
