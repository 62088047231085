import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useTableParameters } from "../../hooks/useTableParameters";
import { TimestampConverter } from "../../Utils/TimestampConverter";
import { AdminPanel } from "../../UI/AdminPanel/AdminPanel";
import { Button } from "../../UI/Button/Button";
import { Input } from "../../UI/Input/Input";
import { MoreButton } from "../../UI/MoreButton/MoreButton";
import { Popup } from "../../UI/Popup/Popup";
import { EditIcon, DeleteIcon, AddSquareIcon } from "../../assets/svgs";
import { useTranslation } from "react-i18next";
import ConfirmModal from "../../UI/ConfirmModal/ConfirmModal";

import "react-toastify/dist/ReactToastify.css";

const Investment = () => {
  const { t } = useTranslation();
  const axios = useAxiosPrivate();
  const { tableFilterData, th, mobile, mobileExpand, mobileExpandFunc } =
    useTableParameters("investment");
  const company_id = useSelector((state) => state.user.companyId);
  const user = useSelector((state) => state.user.user);

  const [pageNow, setPageNow] = useState(1);
  const [pageAll, setPageAll] = useState(1);
  const [edit, setEdit] = useState(false);
  const [tableFilterOutcomingData, setTableFilterOutcomingData] = useState({});
  const [popupShow, setPopupShow] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [exCategory, setExCategory] = useState([]);
  const [accountCodes, setAccountCodes] = useState([]);

  //need change name
  const [showModal, setShowModal] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);

  const [popUpData, setPopUpData] = useState({
    description: "",
    account_code: "",
    document: "",
    amount: 0,
    category: "",
    bank_name: "",
    bank_account_numbr: "",
    company_id: company_id,
    user_id: user,
    _id: "",
  });

  const inputs = [
    {
      title: "Category",
      name: "category",
      type: "lable-input-select",
      options: exCategory,
      defaultAny: "Select",
      onChange: (e) =>
        setPopUpData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
    {
      title: "Account Code",
      name: "account_code",
      type: "lable-input-select",
      options: accountCodes,
      value: popUpData.account_code,
      onChange: (e) =>
        setPopUpData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
    {
      title: "Description",
      name: "description",
      type: "default",
      placeholder: "Description",
      value: popUpData.description,
      onChange: (e) =>
        setPopUpData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
    {
      title: "Document",
      name: "document",
      type: "default",
      placeholder: "Document",
      value: popUpData.document,
      onChange: (e) =>
        setPopUpData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
    {
      title: "Bank Name",
      name: "bank_name",
      type: "default",
      placeholder: "Bank Name",
      value: popUpData.bank_name,
      onChange: (e) =>
        setPopUpData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
    {
      title: "Bank Account Number",
      name: "bank_account_numbr",
      type: "default",
      placeholder: "Bank Account Number",
      value: popUpData.bank_account_numbr,
      onChange: (e) =>
        setPopUpData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
    {
      title: "Amount",
      name: "amount",
      type: "number",
      placeholder: "Amount",
      value: popUpData.amount,
      onChange: (e) =>
        setPopUpData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
  ];

  const clearPopUpData = () => {
    setDisabledBtn(false);
    setPopUpData({
      description: "",
      account_code: "",
      document: "",
      amount: 0,
      category: "",
      bank_name: "",
      bank_account_numbr: "",
      company_id: company_id,
      user_id: user,
      _id: "",
    });
  };

  const handleInputChange = (e, params) => {
    const { name, onChange } = params;

    let data;
    if (!e.target) {
      data = {
        target: {
          value: e,
          name,
        },
      };
      return onChange(data);
    }

    onChange(e);
  };

  const getinvestmentList = async (page, limit = 20) => {
    setIsLoading(true);

    try {
      const res = await axios.post("/investment/get-all-investments", {
        company_id,
        tableFilterOutcomingData,
        page: pageNow, // Pass page parameter
        limit, // Pass limit parameter
      });

      if (res.data) {
        setPageAll(res.data.totalPages);
        setData(res.data.investments);
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteinvestment = async (item) => {
    const { _id } = item;
    setIsLoading(true);
    await axios.post("/investment/delete-investment", { _id }).then((res) => {
      getinvestmentList();
      toast(res?.data?.message);
    });
  };

  async function getCategorys() {
    const transformData = (data) => {
      return data.map((item) => ({
        name: item.name,
        value: item._id,
      }));
    };
    await axios
      .post("/investment-category/get-all-investment-categories", {
        company_id,
      })
      .then((res) => {
        setExCategory(transformData(res.data.investmentCategories));
      });
  }

  const investmentHandler = async () => {
    setDisabledBtn(true);
    await axios
      .post(
        `${edit ? "/investment/edit-investment" : "/investment/add-new-investment"}`,
        popUpData
      )
      .then((res) => {
        clearPopUpData();
        toast(res.data.message);
        setPopupShow(null);
        getinvestmentList();
        setEdit(false);
      })
      .catch((err) => {
        err.response.data.message && toast(err.response.data.message);
        err.message && toast(err.message);
      });
  };

  const fillPopupDataHandler = async (item) => {
    setPopUpData({
      description: item.description || "",
      account_code: item?.account_code._id || "",
      document: item.document || "",
      amount: item.amount || 0,
      category: item.category._id || "",
      bank_name: item.payment_method.method,
      bank_account_numbr: item.payment_method.details,
      _id: item._id || "",
    });
  };

  const getAccountCodes = async () => {
    try {
      const res = await axios.post("/account-codes/get-all-account-codes", {
        company_id,
      });

      if (res.data) {
        const formattedCodes = res.data.accountCodes.map((code) => ({
          name: `${code.code} - ${code.description}`,
          value: code._id,
        }));
        setAccountCodes(formattedCodes); // Save fetched account codes
      }
    } catch (error) {
      console.error("Error fetching account codes:", error);
    }
  };

  useEffect(() => {
    getAccountCodes();
    getCategorys();
  }, []);

  useEffect(() => {
    getinvestmentList();
  }, [tableFilterOutcomingData, pageNow]);

  useEffect(() => {
    setPageNow(1);
  }, [tableFilterOutcomingData]);

  const handleDeleteClick = (orderID) => {
    setOrderToDelete(orderID);
    setShowModal(true);
  };

  const handleEditClick = (item) => {
    setPopupShow("Edit Expense");
    setEdit(true);
    fillPopupDataHandler(item);
  };

  const confirmDelete = () => {
    if (orderToDelete) {
      deleteinvestment(orderToDelete);
      setShowModal(false);
      setOrderToDelete(null);
    }
  };

  const cancelDelete = () => {
    setShowModal(false);
    setOrderToDelete(null);
  };

  let tableData;
  tableData = data?.map((item, index) => {
    return (
      <div key={index} style={{width: "100%"}}>
        <div
          className={`table-parent ${mobileExpand === index ? "active" : ""}`}
        >
          <div className="table">
            <div
              className={`td ${th[0].mobileWidth ? true : false}`}
              style={{ width: `${mobile ? th[0].mobileWidth : th[0].width}%` }}
            >
              <span>{item?.category?.name}</span>
            </div>
            <div
              className={`td ${th[1].mobileWidth ? true : false}`}
              style={{ width: `${mobile ? th[1].mobileWidth : th[1].width}%` }}
            >
              <span>{item?.account_code?.code}</span>
            </div>
            <div
              className={`td ${th[2].mobileWidth ? true : false}`}
              style={{ width: `${mobile ? th[2].mobileWidth : th[2].width}%` }}
            >
              <span>{item?.description}</span>
            </div>
            <div
              className={`td ${th[3].mobileWidth ? true : false}`}
              style={{ width: `${mobile ? th[3].mobileWidth : th[3].width}%` }}
            >
              <span>{item?.document}</span>
            </div>
            <div
              className={`td ${th[4].mobileWidth ? true : false}`}
              style={{ width: `${mobile ? th[4].mobileWidth : th[4].width}%` }}
            >
              <span>{item?.payment_method?.details}</span>
            </div>
            <div
              className={`td ${th[5].mobileWidth ? true : false}`}
              style={{ width: `${mobile ? th[5].mobileWidth : th[5].width}%` }}
            >
              <span>{item?.amount}</span>
            </div>
            <div
              className={`td ${th[6].mobileWidth ? true : false}`}
              style={{ width: `${mobile ? th[6].mobileWidth : th[6].width}%` }}
            >
              <span>{TimestampConverter(item?.createdAt, false)}</span>
            </div>
            <div
              className={`td ${th[7].mobileWidth ? true : false}`}
              style={{
                width: `${mobile ? th[7].mobileWidth : th[7].width}%`,
                gap: "10px",
                justifyContent: "center",
              }}
            >
              <EditIcon onClick={() => handleEditClick(item)} />
              <DeleteIcon onClick={() => handleDeleteClick(item)} />
            </div>
          </div>
        </div>
        {showModal && (
          <ConfirmModal
            message="Are you sure you want to delete this investment?"
            onConfirm={confirmDelete}
            onCancel={cancelDelete}
            icon={<DeleteIcon />}
          />
        )}
      </div>
    );
  });

  return (
    <>
      <AdminPanel
        adminPage={"table"}
        tableData={tableData}
        pageLabel={t("investments")}
        dataLoading={isLoading}
        tableEmulator={false}
        tableHead={th}
        mobile={mobile}
        // tableHeader={1}
        tableFilter={true}
        tableFilterData={tableFilterData}
        setTableFilterOutcomingData={setTableFilterOutcomingData}
        paginationCurrent={pageNow}
        paginationTotal={pageAll}
        paginationEvent={(page) => setPageNow(page)}
        searchLabel={t("investments")}
        tableHeaderButtons={
          <>
            <Button
              label={t("addInvestment")}
              size={"btn-lg"}
              type={"btn-primary"}
              element={"button"}
              svg={<AddSquareIcon />}
              onClick={() => setPopupShow("Add investment")}
            />
          </>
        }
      />
      {popupShow && (
        <Popup
          label={popupShow}
          inputs={inputs}
          handlePopUpClose={() => {
            setPopupShow(null);
            clearPopUpData();
          }}
          popUpData={popUpData}
          setPopUpData={setPopUpData}
          customStyles={{ minWidth: "60%" }}
          // description={"rame"}
          popUpElement={
            <div className="popupElementContent">
              <div className="pdf-inputs">
                {inputs?.map((params, index) => {
                  return (
                    <div className="exchange-input-wrapper" key={index}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span className="inputLabelColumn">
                          {params?.title}
                        </span>
                        {params.required && (
                          <span style={{ color: "red", marginLeft: "10px" }}>
                            *
                          </span>
                        )}
                      </div>
                      <Input
                        key={index}
                        type={params?.type}
                        // label={params?.title}
                        defaultData={params?.options}
                        value={params?.value || ""}
                        name={params.name}
                        customStyles={{ width: "100%" }}
                        selectHandler={(opt) => {
                          handleInputChange(opt, params);
                        }}
                        selectLabel={"select"}
                        placeholder={params?.placeholder}
                        onChange={(e) => handleInputChange(e, params)}
                        customInputStyles={{
                          border: "1px solid rgba(255, 255, 255, 0.1)",
                        }}
                        editable={edit}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="popup-buttons-wrapper">
                <Button
                  label={t("clearForm")}
                  type={"btn-secondary"}
                  element={"button"}
                  onClick={clearPopUpData}
                  size={"btn-lg"}
                  customStyles={{
                    width: "40%",
                  }}
                  disabled={disabledBtn}
                />
                <Button
                  label={t("addInvestment")}
                  type={"btn-primary"}
                  element={"button"}
                  onClick={investmentHandler}
                  size={"btn-lg"}
                  customStyles={{
                    width: "40%",
                  }}
                  svg={<AddSquareIcon />}
                  disabled={disabledBtn}
                />
              </div>
            </div>
          }
        />
      )}
      <ToastContainer theme="dark" />
    </>
  );
};

export default Investment;
