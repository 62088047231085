import React, { useState } from "react";
import { DeleteIcon } from "../../assets/svgs";
import { useOrderContext } from "../../components/Order/OrderContext";
import { TimestampConverter } from "../../Utils/TimestampConverter";
import StatusSelect from "../StatusSelect/StatusSelect";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { Popup } from "../Popup/Popup";
import PdfFile from "../../components/PdfFolder/PdfFile";

import styles from "./InvoiceCard.module.css";

const InvoiceCard = ({ invoice }) => {
  const { t } = useTranslation();
  const axios = useAxiosPrivate();
  const { setReload } = useOrderContext();

  const [showModal, setShowModal] = useState(false);
  const [invoiseDelete, setinvoiseDelete] = useState(null);

  const [popupShow, setPopupShow] = useState(false);
  const [popUpData, setPopUpData] = useState({});

  let statuses = [
    { name: t("statuses.paid"), value: "paid" },
    { name: t("statuses.unpaid"), value: "unpaid" },
    { name: t("statuses.canceled"), value: "canceled" },
  ];

  // 1. Change status
  const changeInvoicestatus = async (id, status) => {
    try {
      const res = await axios.post("/invoice/change-invoice-pay-status", {
        invoiceId: id,
        status,
      });
      toast(res.data.message);
      setReload((prev) => !prev);
    } catch (error) {
      toast.error("Error changing invoice status");
    }
  };

  const deleteInvoice = async (id) => {
    try {
      const res = await axios.post("/invoice/delete-invoice", { id });
      setReload((prev) => !prev);
      toast(res.data.message);
    } catch (error) {
      toast.error("Error deleting invoice");
    }
  };

  const handleDeleteClick = (orderID) => {
    setinvoiseDelete(orderID);
    setShowModal(true);
  };

  const confirmDelete = () => {
    if (invoiseDelete) {
      deleteInvoice(invoiseDelete);
      setShowModal(false);
      setinvoiseDelete(null);
    }
  };

  const cancelDelete = () => {
    setShowModal(false);
    setinvoiseDelete(null);
  };

  const fillPopupData = (item) => {
    setPopUpData({
      ...item,
    });
  };

  const openPdfPopup = () => {
    fillPopupData(invoice);
    setPopupShow(true);
  };

  return (
    <>
      <div className={styles.invoiceCardContainer} onClick={openPdfPopup}>
        <div className={styles.headerContainer}>
          <span className={styles.invoiceNumber}>
            {t("orders.invoice")}: {invoice?.invoice_number}
          </span>
          <div onClick={(e) => e.stopPropagation()}>
            <DeleteIcon onClick={() => handleDeleteClick(invoice._id)} />
          </div>
        </div>
        <div className={styles.invoiceInfo}>
          <span>
            {t("orders.Total")} - {invoice?.total_price}
          </span>
          <div
            className={styles.invoiceStatus}
            onClick={(e) => e.stopPropagation()}
          >
            <span style={{ fontSize: "12px" }}>
              {TimestampConverter(invoice?.createdAt, true)}
            </span>
            <StatusSelect
              value={invoice.status}
              selectHandler={(e) =>
                changeInvoicestatus(invoice._id, e.target.value)
              }
              itemId={invoice._id}
              statuses={statuses}
            />
          </div>
        </div>
      </div>

      {showModal && (
        <ConfirmModal
          message="Are you sure you want to delete this invoice?"
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
          icon={<DeleteIcon />}
        />
      )}

      {popupShow && (
        <Popup
          label="Invoice"
          handlePopUpClose={() => {
            setPopupShow(false);
            setPopUpData({});
          }}
          customStyles={{ width: "auto" }}
          popUpElement={
            <div className="invoice-popup-wrapper">
              <PdfFile popUpData={popUpData} />
            </div>
          }
        />
      )}
    </>
  );
};

export default InvoiceCard;
