import React from "react";
import { Tabs } from "../../UI/Tabs/Tabs";
import { Input } from "../../UI/Input/Input";
import { Button } from "../../UI/Button/Button";
import Xsvg from "../../UI/Input/svg/Xsvg";
import ServiceCard from "./ServiceCard";
import { useTranslation } from "react-i18next";
import { getServiceInputs } from "./inputs";
import styles from "./ServiceTabs.module.css"; // Adjust the path accordingly
import { AddSquareIcon } from "../../assets/svgs";

const ServiceTabs = ({
  activeTab,
  setActiveTab,
  markedServices,
  setMarkedServices,
  handleInputChange,
  edit,
  disabledBtn,
  popUpData,
  setPopUpData,
  serviceTypes,
}) => {
  const { t } = useTranslation();
  const serviceInputs = getServiceInputs(popUpData, setPopUpData, activeTab, t);

  const addService = () => {
    const newService = {
      serviceName: activeTab,
      type: markedServices,
      start_date: popUpData.start_date || "",
      end_date: popUpData.end_date || "",
      location: popUpData.location || "",
      destination: popUpData.destination || "",
      visa_type: popUpData.visa_type || "",
      hotel_name: popUpData.hotel_name || "",
      invitation_type: popUpData.invitation_type || "",
      insurance_provider: popUpData.insurance_provider || "",
      zone: popUpData.zone || "",
      baggage_type: popUpData.baggage_type || "",
      baggage_weight: popUpData.baggage_weight || "",
    };

    setPopUpData((prev) => ({
      ...prev,
      services: [...prev.services, newService],
    }));

    // Reset relevant fields after adding a service
    setPopUpData((prev) => ({
      ...prev,
      start_date: "",
      end_date: "",
      location: "",
      destination: "",
      detales: "",
      visa_type: "",
      hotel_name: "",
      invitation_type: "",
      insurance_provider: "",
      zone: "",
      baggage_type: "",
      baggage_weight: "",
    }));
  };

  const addSameService = (existingService) => {
    const newService = { ...existingService };
    setPopUpData((prev) => ({
      ...prev,
      services: [...prev.services, newService],
    }));
  };

  const removeService = (index) => {
    setPopUpData((prev) => ({
      ...prev,
      services: prev.services.filter((_, i) => i !== index),
    }));
  };

  return (
    <div className={styles.serviceTabsWrapper}>
      {/* ---------- HEADER SECTION ---------- */}
      <div className={styles.headerSection}>
        <h2 className={styles.title}>{t("ServiceTabs.selectService")}</h2>
        <div className={styles.descriptionWrapper}>
          <span className={styles.clickEmoji} aria-hidden="true">
            👇
          </span>
          <p className={styles.description}>{t("ServiceTabs.chooseOne")}</p>
        </div>
      </div>

      {/* ---------- TABS ---------- */}
      <Tabs
        type="simple"
        tabsData={serviceTypes}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setMarkedServices={setMarkedServices}
        customStyles={{
          flexWrap: "wrap",
        }}
      />
      {/* ---------- EXISTING SERVICES LIST ---------- */}
      <div className={styles.servicesWrapper}>
        {activeTab && (
          <div className={`${styles.addServiceContainer}`}>
            <div
              className={styles.closeServiceContainer}
              onClick={() => setActiveTab("")}
            >
              <Xsvg />
            </div>
            <div className={styles.serviseInputs}>
              {serviceInputs.map((params, index) => (
                <div className="exchange-input-wrapper" key={index}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className="inputLabelColumn">{params?.title}</span>
                    {params.required && (
                      <span style={{ color: "red", marginLeft: "10px" }}>
                        *
                      </span>
                    )}
                  </div>
                  <Input
                    key={index}
                    type={params.type}
                    // label={params.title}
                    defaultData={params.options}
                    value={params.value || ""}
                    name={params.name}
                    selectHandler={(opt) => {
                      handleInputChange(opt, params);
                    }}
                    selectLabel={"select"}
                    placeholder={params.placeholder}
                    onChange={(e) => handleInputChange(e, params)}
                    editable={edit}
                  />
                </div>
              ))}
            </div>

            <div className={styles.headerAndAddButton}>
              {/* Description + finger emoji */}
              <p className={styles.btnDescription}>
                {t("ServiceTabs.clickToAdd")}
                <span
                  role="img"
                  aria-hidden="true"
                  className={styles.fingerEmoji}
                >
                  👉
                </span>
              </p>

              {/* Actual Button */}
              <Button
                label={`Add ${activeTab}`}
                type="btn-secondary"
                element="button"
                onClick={addService}
                customStyles={{
                  padding: "20px",
                  height: "50px",
                }}
                svg={<AddSquareIcon />}
                disabled={disabledBtn}
              />
            </div>
          </div>
        )}
        {popUpData?.services?.length > 0 ? (
          <div className={styles.servicesContainer}>
            {popUpData.services.map((service, index) => (
              <ServiceCard
                key={index}
                service={service}
                index={index}
                removeService={removeService}
                addSameService={addSameService}
                edit={edit}
              />
            ))}
          </div>
        ) : (
          <div
            className={styles.emptyState}
            style={{ display: activeTab === "" ? "none" : "flex" }}
          >
            <h3>No services added yet</h3>
            <p>
              Once you add services, they'll appear here. Feel free to click the
              “Add {`${activeTab}`}" button to get started!
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceTabs;
