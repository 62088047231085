import React, { useState } from "react";
import UserCard from "../UserCard/UserCard";
import ServiceCard from "../ServiceCard/ServiceCard";
import InvoiceCard from "../InvoiceCard/InvoiceCard";
import AddItemCard from "../AddItemCard/AddItemCard";
import StatusSelect from "../StatusSelect/StatusSelect";
import { useTranslation } from "react-i18next";
import { TimestampConverter } from "../../Utils/TimestampConverter";
import { useOrderContext } from "../../components/Order/OrderContext";
import { DeleteIcon } from "../../assets/svgs";
import ConfirmModal from "../../UI/ConfirmModal/ConfirmModal";

import styles from "./OrderCard.module.css";

const OrderCard = ({ order, changeOrderStatus, deleteOrder }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);

  let statuses = [
    { name: t("statuses.completed"), value: "paid" },
    { name: t("statuses.pending"), value: "unpaid" },
    { name: t("statuses.canceled"), value: "canceled" },
  ];

  const { setSelectedOrder, setPopupShow, setAddService, addService } =
    useOrderContext();

  const handleDeleteClick = (orderID) => {
    setOrderToDelete(orderID);
    setShowModal(true);
  };

  const confirmDelete = () => {
    if (orderToDelete) {
      deleteOrder(orderToDelete);
      setShowModal(false);
      setOrderToDelete(null);
    }
  };

  const cancelDelete = () => {
    setShowModal(false);
    setOrderToDelete(null);
  };

  const totalServicesCost =
    order?.services?.reduce((sum, service) => {
      const serviceItem = service?.service_item_id || service;
      return sum + (serviceItem?.amount || 0);
    }, 0) || 0;

  const totalPaid = order?.invoices
    ?.filter((inv) => inv.status === "paid")
    .reduce((sum, inv) => sum + (inv.total_price || 0), 0);

  const totalUnpaid = order?.invoices
    ?.filter((inv) => inv.status === "unpaid")
    .reduce((sum, inv) => sum + (inv.total_price || 0), 0);

  const totalInvoices = totalPaid + totalUnpaid;

  const percentage =
    totalInvoices > 0
      ? (((totalInvoices - totalServicesCost) / totalInvoices) * 100).toFixed(2)
      : 0;

  return (
    <>
      <div className={styles.orderCardContainer}>
        <div className={styles.headerContainer}>
          <UserCard
            name={order?.customer_id?.full_name}
            number={order?.customer_id?.phone_number?.number}
            email_address={order?.customer_id?.email_address}
            statuse={order?.customer_id?.status}
          />
          <div className={styles.orderInfo}>
            <DeleteIcon onClick={() => handleDeleteClick(order._id)} />
            {/* <span>Order ID:{" "}{order?._id} </span> */}
            <span>{TimestampConverter(order?.createdAt, true)}</span>
          </div>
        </div>
        <div className={styles.orderCOntent}>
          <div className={styles.orderServices}>
            <span className={styles.cardTitle}>{t("orders.Services")}:</span>
            <div className={styles.cardList}>
              <div className={styles.serviceCardList}>
                <AddItemCard
                  content={t("orders.Add Service")}
                  onClick={() => {
                    setPopupShow("add service");
                    setAddService({ ...addService, _id: order._id });
                  }}
                />
                {order?.services.length > 0 &&
                  order?.services.map((service, index) => {
                    const serviceItem = service?.service_item_id || service;
                    return <ServiceCard service={serviceItem} key={index} />;
                  })}
              </div>
            </div>
          </div>
          <div className={styles.orderInvoices}>
            <span className={styles.cardTitle}>{t("orders.invoices")}:</span>
            <div className={styles.cardList}>
              <AddItemCard
                content={t("orders.Generate Invoice")}
                onClick={() => {
                  setPopupShow("Generate Invoice");
                  setSelectedOrder(order);
                }}
              />
              <div className={styles.serviceCardList}>
                {order?.invoices.length > 0 &&
                  order?.invoices.map((invoice, index) => {
                    return <InvoiceCard invoice={invoice} key={index} />;
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.orderFooter}>
          <StatusSelect
            value={order.status}
            selectHandler={(e) => changeOrderStatus(order._id, e.target.value)}
            itemId={order._id}
            statuses={statuses}
            customStyles={{ fontSize: "18px", padding: "8px" }}
          />
          <div className={styles.companyCostContainer}>
            <span>
              {t("orders.Company Cost")} : {totalServicesCost} GEL
            </span>
            <span>
              {t("orders.Profit")} : {totalInvoices - totalServicesCost} GEL{" "}
            </span>
            <span>
              {t("orders.Percentage")} : {percentage}%
            </span>
          </div>
          <div className={styles.orderTotal}>
            <span className={styles.payableAmount}>
              {t("orders.Remaining")} : {totalUnpaid} GEL
            </span>
            <span className={styles.alreadyPaid}>
              {t("orders.Paid for")} : {totalPaid} GEL
            </span>
            <span className={styles.totalPrice}>
              {t("orders.Total")} : {totalInvoices} GEL
            </span>
          </div>
        </div>
      </div>
      {showModal && (
        <ConfirmModal
          message="Are you sure you want to delete this order?"
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
          icon={<DeleteIcon />}
        />
      )}
    </>
  );
};

export default OrderCard;
