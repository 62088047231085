import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useTableParameters } from "../../hooks/useTableParameters";
import { AdminPanel } from "../../UI/AdminPanel/AdminPanel";
import { Button } from "../../UI/Button/Button";
import { Input } from "../../UI/Input/Input";
import { MoreButton } from "../../UI/MoreButton/MoreButton";
import { Popup } from "../../UI/Popup/Popup";
import { useTranslation } from "react-i18next";
import ConfirmModal from "../../UI/ConfirmModal/ConfirmModal";

import { EditIcon, DeleteIcon, AddSquareIcon } from "../../assets/svgs";

import "react-toastify/dist/ReactToastify.css";

const AccountCodes = () => {
  const { t } = useTranslation();
  const axios = useAxiosPrivate();
  const { tableFilterData, th, mobile, mobileExpand, mobileExpandFunc } =
    useTableParameters("accountcode");
  const company_id = useSelector((state) => state.user.companyId);
  const user = useSelector((state) => state.user.user);

  const [pageNow, setPageNow] = useState(1);
  const [pageAll, setPageAll] = useState(1);
  const [edit, setEdit] = useState(false);
  const [tableFilterOutcomingData, setTableFilterOutcomingData] = useState({});
  const [popupShow, setPopupShow] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);

  //need change name
  const [showModal, setShowModal] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);

  const [popUpData, setPopUpData] = useState({
    code: "",
    description: "",
    company_id: company_id,
    user_id: user,
    _id: "",
  });

  const inputs = [
    {
      title: t("code"),
      name: "code",
      type: "default",
      placeholder: t("enterCode"),
      value: popUpData.code,
      onChange: (e) =>
        setPopUpData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
    {
      title: t("description"),
      name: "description",
      type: "default",
      placeholder: t("enterDescription"),
      value: popUpData.description,
      onChange: (e) =>
        setPopUpData((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
  ];

  const clearPopUpData = () => {
    setEdit(false);
    setDisabledBtn(false);
    setPopUpData({
      code: "",
      description: "",
      company_id: company_id,
      user_id: user,
      _id: "",
    });
  };

  const handleInputChange = (e, params) => {
    const { name, onChange } = params;

    let data;
    if (!e.target) {
      data = {
        target: {
          value: e,
          name,
        },
      };
      return onChange(data);
    }

    onChange(e);
  };

  const getAccountCodeList = async (page, limit = 20) => {
    setIsLoading(true);

    try {
      const res = await axios.post("/account-codes/get-all-account-codes", {
        company_id,
        tableFilterOutcomingData,
        page: pageNow, // Pass page parameter
        limit, // Pass limit parameter
      });

      if (res.data) {
        setData(res.data.accountCodes);
        setPageAll(res?.data?.totalPages);
      }
    } catch (error) {
      console.error("Error fetching account codes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAccountCode = async (item) => {
    const { _id } = item;
    setIsLoading(true);
    await axios
      .post("/account-codes/delete-account-code", { _id })
      .then((res) => {
        getAccountCodeList();
        toast(res?.data?.message);
      });
  };

  const accountCodeHandler = async () => {
    setDisabledBtn(true);
    await axios
      .post(
        `${edit ? "/account-codes/edit-account-code" : "/account-codes/add-new-account-code"}`,
        popUpData
      )
      .then((res) => {
        clearPopUpData();
        toast(res.data.message);
        setPopupShow(null);
        getAccountCodeList();
        setEdit(false);
      })
      .catch((err) => {
        err.response.data.message && toast(err.response.data.message);
        err.message && toast(err.message);
      });
  };

  const fillPopupDataHandler = async (item) => {
    setPopUpData({
      code: item.code || "",
      description: item.description || "",
      company_id: company_id,
      user_id: user,
      _id: item._id || "",
    });
  };

  useEffect(() => {
    getAccountCodeList();
  }, [tableFilterOutcomingData, pageNow]);

  useEffect(() => {
    setPageNow(1);
  }, [tableFilterOutcomingData]);

  const handleDeleteClick = (orderID) => {
    setOrderToDelete(orderID);
    setShowModal(true);
  };

  const handleEditClick = (item) => {
    setPopupShow(t("editAccountCode"));
    setEdit(true);
    fillPopupDataHandler(item);
  };

  const confirmDelete = () => {
    if (orderToDelete) {
      deleteAccountCode(orderToDelete);
      setShowModal(false);
      setOrderToDelete(null);
    }
  };

  const cancelDelete = () => {
    setShowModal(false);
    setOrderToDelete(null);
  };

  let tableData;
  tableData = data?.map((item, index) => {
    return (
      <div key={index} style={{ width: "100%" }}>
        <div
          className={`table-parent ${mobileExpand === index ? "active" : ""}`}
        >
          <div className="table">
            <div
              className={`td ${th[0].mobileWidth ? true : false}`}
              style={{ width: `${mobile ? th[0].mobileWidth : th[0].width}%` }}
            >
              <span>{item?.code}</span>
            </div>
            <div
              className={`td ${th[1].mobileWidth ? true : false}`}
              style={{ width: `${mobile ? th[1].mobileWidth : th[1].width}%` }}
            >
              <span>{item?.description}</span>
            </div>
            <div
              className={`td ${th[2].mobileWidth ? true : false}`}
              style={{
                width: `${mobile ? th[2].mobileWidth : th[2].width}%`,
                gap: "10px",
                justifyContent: "center",
              }}
            >
              <EditIcon onClick={() => handleEditClick(item)} />
              <DeleteIcon onClick={() => handleDeleteClick(item)} />
            </div>
          </div>
        </div>
        {showModal && (
          <ConfirmModal
            message="Are you sure you want to delete this account code?"
            onConfirm={confirmDelete}
            onCancel={cancelDelete}
            icon={<DeleteIcon />}
          />
        )}
      </div>
    );
  });

  return (
    <>
      <AdminPanel
        adminPage={"table"}
        tableData={tableData}
        pageLabel={t("accountCodes")}
        dataLoading={isLoading}
        tableEmulator={false}
        tableHead={th}
        mobile={mobile}
        // tableHeader={1}
        tableFilter={true}
        tableFilterData={tableFilterData}
        setTableFilterOutcomingData={setTableFilterOutcomingData}
        paginationCurrent={pageNow}
        paginationTotal={pageAll}
        paginationEvent={(page) => setPageNow(page)}
        tableHeaderButtons={
          <>
            <Button
              label={t("addAccountCode")}
              size={"btn-lg"}
              type={"btn-primary"}
              element={"button"}
              svg={<AddSquareIcon />}
              onClick={() => setPopupShow(t("addAccountCode"))}
            />
          </>
        }
      />
      {popupShow && (
        <Popup
          label={popupShow}
          inputs={inputs}
          handlePopUpClose={() => {
            setPopupShow(null);
            clearPopUpData();
          }}
          popUpData={popUpData}
          setPopUpData={setPopUpData}
          customStyles={{ minWidth: "40%" }}
          popUpElement={
            <div className="manual-inputs">
              {inputs?.map((params, index) => {
                return (
                  <div className="exchange-input-wrapper" key={index}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className="inputLabelColumn">{params?.title}</span>
                      {params.required && (
                        <span style={{ color: "red", marginLeft: "10px" }}>
                          *
                        </span>
                      )}
                    </div>
                    <Input
                      key={index}
                      type={params?.type}
                      // label={params?.title}
                      value={popUpData[params.name]}
                      name={params.name}
                      placeholder={params?.placeholder}
                      onChange={(e) => handleInputChange(e, params)}
                    />
                  </div>
                );
              })}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  label={t("addAccountCode")}
                  size={"btn-lg"}
                  type={"btn-primary"}
                  element={"button"}
                  onClick={accountCodeHandler}
                  customStyles={{
                    width: "80%",
                  }}
                  svg={<AddSquareIcon />}
                  disabled={disabledBtn}
                />
              </div>
            </div>
          }
        />
      )}
      <ToastContainer theme="dark" />
    </>
  );
};

export default AccountCodes;
