import React from "react";
import { DeleteIcon, EditIcon } from "../../assets/svgs";

import PlusSvg from "../../assets/svgs/PlusSvg";
import InvoiceSvg2 from "../../assets/svgs/InvoiceSvg2";
import EmailSvg from "../../assets/svgs/EmailSvg";

import styles from "./UserCard.module.css";

const UserCard = ({ name, number, email_address, statuse }) => {
  return (
    <div className={styles.userCard}>
      <div className={styles.userImage}>
        <img src="/user.jpeg" alt="user" />
      </div>
      <div className={styles.userContent}>
        <div className={styles.actionsCont}>
          <span className={styles.userName}>{name}</span>
          <div className={styles.actions}>
            <InvoiceSvg2 />
            {/* <PlusSvg /> */}
            <EditIcon />
            <DeleteIcon />
          </div>
        </div>
        <div className={styles.userEmailCont}>
          <span>{email_address}</span>
          <span className={`${statuse}`}>{statuse}</span>
        </div>
        <div className={styles.userPhoneCont}>
          <span className={styles.userPhone}>{number}</span>
          <EmailSvg />
        </div>
      </div>
    </div>
  );
};

export default UserCard;
