import React from "react";

const ArrowDownSvg = ({ active }) => {
  return (
    <svg
      className={`${active ? "admin-arrow-rotate" : ""} ${"admin-arrow"}`}
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3_765)">
        <path
          d="M16 4.33198L14.59 3L10 7.32659L5.41 3L4 4.33198L10 10L16 4.33198Z"
          fill="black"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3_765"
          x="0"
          y="0"
          width="20"
          height="15"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3_765"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3_765"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ArrowDownSvg;
