import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ArrowDownSvg from "../../assets/svgs/ArrowDownSvg";

import styles from "./LanguageSwitcher.module.css";

const LanguageSwitcher = ({ type, isOpen2, setIsOpen2 }) => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const switcherRef = useRef(null);

  const currentLanguage = localStorage.getItem("language") || i18n.language;

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("language", lang);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (switcherRef.current && !switcherRef.current.contains(event.target)) {
      type === "landing" ? setIsOpen(false) : setIsOpen2(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage && savedLanguage !== i18n.language) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);
  let element = "";

  if (type === "landing") {
    element = (
      <div className={styles.switcherContainer} ref={switcherRef}>
        <button onClick={toggleDropdown} className={styles.languageButton}>
          {currentLanguage === "en" ? "EN" : "GE"}
          <ArrowDownSvg active={isOpen} />
        </button>
        <div
          className={`${styles.dropdownMenu} ${isOpen ? styles.active : ""}`}
        >
          <div
            className={`${styles.dropdownItem} ${currentLanguage === "en" ? styles.active : ""}`}
            onClick={() => changeLanguage("en")}
          >
            En
          </div>
          <div
            className={`${styles.dropdownItem} ${currentLanguage === "ge" ? styles.active : ""}`}
            onClick={() => changeLanguage("ge")}
          >
            Ge
          </div>
        </div>
      </div>
    );
  }

  if (type === "header") {
    element = (
      <div className={styles.switcherContainerHidden} ref={switcherRef}>
        <button className={styles.languageButtonHidden}>
          {currentLanguage === "en" ? "EN" : "GE"}
          <ArrowDownSvg active={isOpen2} />
        </button>
        <div
          className={`${styles.dropdownMenuHidden} ${isOpen2 ? styles.active : ""}`}
        >
          <div
            className={`${styles.dropdownItemHidden} ${currentLanguage === "en" ? styles.active : ""}`}
            onClick={() => changeLanguage("en")}
          >
            En
          </div>
          <div
            className={`${styles.dropdownItemHidden} ${currentLanguage === "ge" ? styles.active : ""}`}
            onClick={() => changeLanguage("ge")}
          >
            Ge
          </div>
        </div>
      </div>
    );
  }

  return element;
};

export default LanguageSwitcher;
