import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ExpenseSvg from "../../assets/svgs/ExpenseSvg";
import InvoiceSvg from "../../assets/svgs/InvoiceSvg";
import FlightsListSvg from "../../assets/svgs/FlightsListSvg";
import OrderSvg from "../../assets/svgs/OrderSvg";
import { OperatorSvg, CustomersSvg, SettingsSvg } from "../../assets/svgs";
import { Button } from "../../UI/Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import StatsSvg from "../../assets/svgs/StatsSvg";
import InvestmentSvg from "../../assets/svgs/InvestmentSvg";
import ArrowDownSvg from "../../assets/svgs/ArrowDownSvg";
import { Dropdown } from "../../UI/Dropdown/Dropdown";

import styles from "../../index.module.css";

const Sidebar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const company = useSelector((state) => state.user.company);
  const roles = useSelector((state) => state.user.roles);
  const [active, setActive] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const sidebarItems = [
    roles?.includes("SUPER_ADMIN") &&
      company?.sections?.statistics && {
        id: 20,
        name: t("Dashboard"),
        route: "/dashboard",
        svg: <StatsSvg />,
        subMenu: [],
        active: true,
      },
    roles?.includes("GOD") && {
      id: 11,
      name: "All Companies",
      route: "/all-company",
      svg: <OperatorSvg />,
      subMenu: [],
      active: true,
    },
    roles?.includes("SUPER_ADMIN") && {
      id: 0,
      name: t("allAccounts"),
      route: "/all-accounts",
      svg: <OperatorSvg />,
      subMenu: [],
      active: true,
    },
    company?.sections?.customers && {
      id: 1,
      name: roles?.includes("SUPER_ADMIN")
        ? t("allCustomers")
        : t("myCustomers"),
      route: "/customers",
      svg: <CustomersSvg />,
      subMenu: [],
      active: true,
    },
    company?.sections?.orders && {
      id: 3,
      name: roles?.includes("SUPER_ADMIN") ? t("allOrders") : t("myOrders"),
      route: "/orders",
      svg: <OrderSvg />,
      subMenu: [],
      active: true,
    },
    company?.sections?.invoices && {
      id: 5,
      name: roles?.includes("SUPER_ADMIN") ? t("allInvoices") : t("myInvoices"),
      route: "/invoice",
      svg: <InvoiceSvg />,
      subMenu: [],
      active: true,
    },
    company?.sections?.services && {
      id: 7,
      name: roles?.includes("SUPER_ADMIN") ? t("allServices") : t("myServices"),
      route: "/services",
      svg: <FlightsListSvg />,
      subMenu: [],
      active: true,
    },
    roles?.includes("SUPER_ADMIN") &&
      company?.sections?.expenses && {
        id: 9,
        name: t("expenses"),
        route: "/expense",
        svg: <ExpenseSvg />,
        subMenu: [],
        active: true,
      },
    roles?.includes("SUPER_ADMIN") &&
      company?.sections?.investments && {
        id: 12,
        name: t("investments"),
        route: "/investment",
        svg: <InvestmentSvg />,
        subMenu: [],
        active: true,
      },
  ].filter(Boolean);

  const settingsDropdown = [
    {
      id: 1,
      title: "",
      list: [
        {
          id: 1,
          title: t("expencesCategory"),
          onClick: () => navigate("/setup/expense-category"),
        },
      ],
    },
    {
      id: 2,
      title: "",
      list: [
        {
          id: 1,
          title: t("investmentCategory"),
          onClick: () => navigate("/setup/investment-category"),
        },
      ],
    },
    {
      id: 3,
      title: "",
      list: [
        {
          id: 1,
          title: t("serviceCompany"),
          onClick: () => navigate("/setup/service-company"),
        },
      ],
    },
    {
      id: 4,
      title: "",
      list: [
        {
          id: 1,
          title: t("serviceType"),
          onClick: () => navigate("/setup/service-type"),
        },
      ],
    },
    {
      id: 5,
      title: "",
      list: [
        {
          id: 1,
          title: t("accountCodes"),
          onClick: () => navigate("/setup/account-codes"),
        },
      ],
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`${styles.sidebar} admin-sidebar`}>
      {sidebarItems.map((item) => (
        <Button
          key={item.id}
          id={item.id}
          label={item.name}
          route={item.route}
          element={"side-admin-button"}
          svg={item.svg}
          subMenu={item.subMenu}
          active={location.pathname === item.route}
          subMenuActive={location.pathname.includes(item.subMenu?.route)}
          ArrowDownSvg={<ArrowDownSvg />}
        />
      ))}
      {roles?.includes("SUPER_ADMIN") && (
        <div className="sidebar-dropdown-section">
          <div
            ref={buttonRef}
            className="side-admin-button"
            onClick={() => setActive(!active)}
          >
            <div className="side-btn-icon">
              <SettingsSvg />
            </div>
            <div className="side-btn-text" >
              <span className="font-18">{t("Setup")}</span>
            </div>
          </div>

          {active && (
            <div
              className={`${"admin-hidden"} ${active ? "admin-select-sidebar" : ""}`}
              ref={dropdownRef}
            >
              <Dropdown
                data={settingsDropdown}
                type={"dropdown"}
                dropdown={"dropdown"}
                active={active}
                customStyles={{
                  width: "100%",
                  zIndex: "19999",
                  backgroundColor: "white",
                }}
                handleListItemClick={() => console.log("")}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Sidebar;
