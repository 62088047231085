import React from "react";
import PluseSvg from "../../assets/svgs/PluseSvg";

import styles from "./AddItemCard.module.css";

const AddItemCard = ({ content, onClick }) => {
  return (
    <div className={styles.addItemContainer} onClick={onClick}>
      <PluseSvg />
      <span>{content}</span>
    </div>
  );
};

export default AddItemCard;
