import React from "react";

const SettingsSvg = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="sidebarSettingsSvg"
    >
      <path
        d="M10 11.5C10.663 11.5 11.2989 11.2366 11.7678 10.7678C12.2366 10.2989 12.5 9.66304 12.5 9C12.5 8.33696 12.2366 7.70107 11.7678 7.23223C11.2989 6.76339 10.663 6.5 10 6.5C9.33696 6.5 8.70107 6.76339 8.23223 7.23223C7.76339 7.70107 7.5 8.33696 7.5 9C7.5 9.66304 7.76339 10.2989 8.23223 10.7678C8.70107 11.2366 9.33696 11.5 10 11.5Z"
        className="sidebarIconsStroke"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66602 9.73336V8.26669C1.66602 7.40003 2.37435 6.68336 3.24935 6.68336C4.75768 6.68336 5.37435 5.6167 4.61602 4.30836C4.18268 3.55836 4.44102 2.58336 5.19935 2.15003L6.64102 1.32503C7.29935 0.933362 8.14935 1.1667 8.54102 1.82503L8.63268 1.98336C9.38268 3.2917 10.616 3.2917 11.3743 1.98336L11.466 1.82503C11.8577 1.1667 12.7077 0.933362 13.366 1.32503L14.8077 2.15003C15.566 2.58336 15.8243 3.55836 15.391 4.30836C14.6327 5.6167 15.2493 6.68336 16.7577 6.68336C17.6243 6.68336 18.341 7.39169 18.341 8.26669V9.73336C18.341 10.6 17.6327 11.3167 16.7577 11.3167C15.2493 11.3167 14.6327 12.3834 15.391 13.6917C15.8243 14.45 15.566 15.4167 14.8077 15.85L13.366 16.675C12.7077 17.0667 11.8577 16.8334 11.466 16.175L11.3743 16.0167C10.6243 14.7084 9.39102 14.7084 8.63268 16.0167L8.54102 16.175C8.14935 16.8334 7.29935 17.0667 6.64102 16.675L5.19935 15.85C4.8361 15.6409 4.57067 15.2961 4.4613 14.8915C4.35194 14.4869 4.40758 14.0554 4.61602 13.6917C5.37435 12.3834 4.75768 11.3167 3.24935 11.3167C2.37435 11.3167 1.66602 10.6 1.66602 9.73336Z"
        className="sidebarIconsStroke"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingsSvg;
