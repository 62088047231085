import React from "react";

const EmailSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.6249 20.35C3.09157 20.35 2.62907 20.1542 2.2374 19.7625C1.84574 19.3708 1.6499 18.9083 1.6499 18.375V5.625C1.6499 5.075 1.84574 4.60417 2.2374 4.2125C2.62907 3.82083 3.09157 3.625 3.6249 3.625H20.3749C20.9249 3.625 21.3957 3.82083 21.7874 4.2125C22.1791 4.60417 22.3749 5.075 22.3749 5.625V18.375C22.3749 18.9083 22.1791 19.3708 21.7874 19.7625C21.3957 20.1542 20.9249 20.35 20.3749 20.35H3.6249ZM11.9999 12.9L3.6249 7.325V18.375H20.3749V7.325L11.9999 12.9ZM11.9999 11.075L20.2749 5.625H3.7499L11.9999 11.075ZM3.6249 7.325V5.625V7.325V18.375V7.325Z"
        fill="#3E54AC"
      />
    </svg>
  );
};

export default EmailSvg;
