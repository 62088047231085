import React, { useState } from "react";
import { DeleteIcon, EditIcon } from "../../assets/svgs";
import { TimestampConverter } from "../../Utils/TimestampConverter";
import { useOrderContext } from "../../components/Order/OrderContext";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { useTranslation } from "react-i18next";

import styles from "./ServiceCard.module.css";

const ServiceCard = ({ service }) => {
  const axios = useAxiosPrivate();
  const { setPopupShow, setEditService } = useOrderContext();
  const [showModal, setShowModal] = useState(false);
  const [serviceDelete, setServiceDelete] = useState(null);
  const { t } = useTranslation();

  const { setReload } = useOrderContext();

  const deleteHandler = async (_id) => {
    try {
      const res = await axios.post("/service-item/delete-service-item", {
        _id,
      });
      toast(res?.data?.message);
      setReload((prev) => !prev);
    } catch (error) {
      toast.error("Error deleting service");
    }
  };

  const handleDeleteClick = (serviceID) => {
    setServiceDelete(serviceID);
    setShowModal(true);
  };

  const confirmDelete = () => {
    if (serviceDelete) {
      deleteHandler(serviceDelete);
      setShowModal(false);
      setServiceDelete(null);
    }
  };

  const cancelDelete = () => {
    setShowModal(false);
    setServiceDelete(null);
  };

  return (
    <>
      <div className={styles.serviceCardContainer}>
        <div className={styles.headerContainer}>
          <span className={styles.serviceName}>
            {service.service_type_id?.name || "-"}
          </span>
          <span>{t("orders.Cost")}: {service?.amount}</span>
          <div>
            <EditIcon
              onClick={(e) => {
                e.stopPropagation();
                setPopupShow("Edit Service");
                setEditService({
                  start_date: service.start_date || "",
                  end_date: service.end_date || "",
                  location: service.location || "",
                  destination: service.destination || "",
                  invoice_pdf: service.invoice_pdf || "",
                  amount: service.amount || 0,
                  document: service.document || "",
                  service_type_id: service?.service_type_id?._id || "",
                  service_company_id: service?.service_company_id || "",
                  _id: service._id || "",
                });
              }}
            />
            <DeleteIcon
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(service._id);
              }}
            />
          </div>
        </div>
        <div className={styles.serviceInfo}>
          <span style={{textWrap: "nowrap"}}>
            {service?.location} {`${service?.destination ? "-" : ""}`}{" "}
            {service?.destination}
          </span>
          <div className={styles.serviceDates}>
            <span style={{ fontSize: "12px" }}>
              {TimestampConverter(service?.start_date, true)}{" "}
              {`${service?.end_date ? "-" : ""}`}{" "}
              {TimestampConverter(service?.end_date, true)}
            </span>
            <span className={`${service?.status}`}>{service?.status}</span>
          </div>
        </div>
      </div>
      {showModal && (
        <ConfirmModal
          message="Are you sure you want to delete this service?"
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
          icon={<DeleteIcon />}
        />
      )}
    </>
  );
};

export default ServiceCard;
