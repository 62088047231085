import React from "react";

const DeleteIcon = ({ onClick }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M14 19.375H4C3.50272 19.375 3.02581 19.1775 2.67417 18.8258C2.32254 18.4742 2.125 17.9973 2.125 17.5V5.625C2.125 5.45924 2.19085 5.30027 2.30806 5.18306C2.42527 5.06585 2.58424 5 2.75 5C2.91576 5 3.07473 5.06585 3.19194 5.18306C3.30915 5.30027 3.375 5.45924 3.375 5.625V17.5C3.375 17.6658 3.44085 17.8247 3.55806 17.9419C3.67527 18.0592 3.83424 18.125 4 18.125H14C14.1658 18.125 14.3247 18.0592 14.4419 17.9419C14.5592 17.8247 14.625 17.6658 14.625 17.5V5.625C14.625 5.45924 14.6908 5.30027 14.8081 5.18306C14.9253 5.06585 15.0842 5 15.25 5C15.4158 5 15.5747 5.06585 15.6919 5.18306C15.8092 5.30027 15.875 5.45924 15.875 5.625V17.5C15.875 17.9973 15.6775 18.4742 15.3258 18.8258C14.9742 19.1775 14.4973 19.375 14 19.375Z"
        fill="#F81D1D"
      />
      <path
        d="M16.5 4.375H1.5C1.33424 4.375 1.17527 4.30915 1.05806 4.19194C0.940848 4.07473 0.875 3.91576 0.875 3.75C0.875 3.58424 0.940848 3.42527 1.05806 3.30806C1.17527 3.19085 1.33424 3.125 1.5 3.125H16.5C16.6658 3.125 16.8247 3.19085 16.9419 3.30806C17.0592 3.42527 17.125 3.58424 17.125 3.75C17.125 3.91576 17.0592 4.07473 16.9419 4.19194C16.8247 4.30915 16.6658 4.375 16.5 4.375Z"
        fill="#F81D1D"
      />
      <path
        d="M11.5 4.375C11.3342 4.375 11.1753 4.30915 11.0581 4.19194C10.9408 4.07473 10.875 3.91576 10.875 3.75V1.875H7.125V3.75C7.125 3.91576 7.05915 4.07473 6.94194 4.19194C6.82473 4.30915 6.66576 4.375 6.5 4.375C6.33424 4.375 6.17527 4.30915 6.05806 4.19194C5.94085 4.07473 5.875 3.91576 5.875 3.75V1.25C5.875 1.08424 5.94085 0.925268 6.05806 0.808058C6.17527 0.690848 6.33424 0.625 6.5 0.625H11.5C11.6658 0.625 11.8247 0.690848 11.9419 0.808058C12.0592 0.925268 12.125 1.08424 12.125 1.25V3.75C12.125 3.91576 12.0592 4.07473 11.9419 4.19194C11.8247 4.30915 11.6658 4.375 11.5 4.375Z"
        fill="#F81D1D"
      />
      <path
        d="M9 16.25C8.83424 16.25 8.67527 16.1842 8.55806 16.0669C8.44085 15.9497 8.375 15.7908 8.375 15.625V6.875C8.375 6.70924 8.44085 6.55027 8.55806 6.43306C8.67527 6.31585 8.83424 6.25 9 6.25C9.16576 6.25 9.32473 6.31585 9.44194 6.43306C9.55915 6.55027 9.625 6.70924 9.625 6.875V15.625C9.625 15.7908 9.55915 15.9497 9.44194 16.0669C9.32473 16.1842 9.16576 16.25 9 16.25Z"
        fill="#F81D1D"
      />
      <path
        d="M12.125 15C11.9592 15 11.8003 14.9342 11.6831 14.8169C11.5658 14.6997 11.5 14.5408 11.5 14.375V8.125C11.5 7.95924 11.5658 7.80027 11.6831 7.68306C11.8003 7.56585 11.9592 7.5 12.125 7.5C12.2908 7.5 12.4497 7.56585 12.5669 7.68306C12.6842 7.80027 12.75 7.95924 12.75 8.125V14.375C12.75 14.5408 12.6842 14.6997 12.5669 14.8169C12.4497 14.9342 12.2908 15 12.125 15Z"
        fill="#F81D1D"
      />
      <path
        d="M5.875 15C5.70924 15 5.55027 14.9342 5.43306 14.8169C5.31585 14.6997 5.25 14.5408 5.25 14.375V8.125C5.25 7.95924 5.31585 7.80027 5.43306 7.68306C5.55027 7.56585 5.70924 7.5 5.875 7.5C6.04076 7.5 6.19973 7.56585 6.31694 7.68306C6.43415 7.80027 6.5 7.95924 6.5 8.125V14.375C6.5 14.5408 6.43415 14.6997 6.31694 14.8169C6.19973 14.9342 6.04076 15 5.875 15Z"
        fill="#F81D1D"
      />
    </svg>
  );
};

export default DeleteIcon;
