import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AdminHeader } from "./components/Header/AdminHeader";

import Unauthorized from "./components/Unauthorized/Unauthorized";
import InvoiceList from "./components/InvoiceList/InvoiceList";
import Customers from "./components/Customers/Customer";
import RequireAuth from "./Utils/RequireAuth";
import AllAccounts from "./components/AllAccounts/AllAccounts";
import ServiceType from "./components/ServiceType/ServiceType";
import Login from "./components/Login/Login";
import Expense from "./components/Expense/Expense";
import ServiceItem from "./components/ServiceItem/ServiceItem";
import ExpenseCategory from "./components/ExpenseCategory/ExpenseCategory";
import Order from "./components/Order/Order";
import ServiceCompany from "./components/ServiceCompany/ServiceCompany";
import CheckInputs from "./UI/CheckInputs";
import Sidebar from "./components/Sidebar/Sidebar";
import Landing from "./components/Landing/Landing";
import Registration from "./components/Registration/Registration";
import TermsOfUse from "./components/Legal/TermsOfUse";
import PrivacyPolicy from "./components/Legal/PrivacyPolicy";
import CompanyProfile from "./components/CompanyProfile/CompanyProfile";
import UserProfile from "./components/UserProfile/UserProfile";
import BotConfig from "./components/Bot/BotConfig";
import AllCompany from "./components/AllCompany/AllCompany";
import Statistic from "./components/Statistics/Statistic";
import Investment from "./components/Investment/Investment";
import InvestmentCategory from "./components/InvestmentCategory/InvestmentCategory";
import AccountCodes from "./components/AccountCodes/AccountCodes";
import { OrderProvider } from "./components/Order/OrderContext";

import "react-toastify/dist/ReactToastify.css";

function App() {
  const location = useLocation();
  const access_token = useSelector((state) => state.user.access_token);
  return (
    <main className="App admin-container">
      <AdminHeader />

      <div
        className="mainContent"
        style={{
          width: access_token ? "calc(100% - 60px)" : "100%",
          marginLeft: access_token ? "60px" : "0",
        }}
      >
        {access_token && <Sidebar />}
        {access_token ? (
          <Routes>
            <Route path="/unauthorized" element={<Unauthorized />} />

            <Route
              element={
                <RequireAuth allowedRoles={["OPERATOR", "SUPER_ADMIN"]} />
              }
            >
              <Route path="/user-account" element={<UserProfile />} />
              <Route path="/customers" element={<Customers />} />
              <Route
                path="/orders"
                element={
                  <OrderProvider>
                    <Order />
                  </OrderProvider>
                }
              />
              <Route path="/invoice" element={<InvoiceList />} />
              <Route path="/services" element={<ServiceItem />} />
              <Route path="/checkinputs" element={<CheckInputs />} />
              <Route path="/all-company" element={<AllCompany />} />
              <Route path="/dashboard" element={<Statistic />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={["SUPER_ADMIN"]} />}>
              <Route path="/company-account" element={<CompanyProfile />} />
              <Route path="/bot-config" element={<BotConfig />} />
              <Route path="/expense" element={<Expense />} />
              <Route path="/investment" element={<Investment />} />
              <Route path="/all-accounts" element={<AllAccounts />} />
              <Route
                path="/Setup/expense-category"
                element={<ExpenseCategory />}
              />
              <Route
                path="/Setup/investment-category"
                element={<InvestmentCategory />}
              />
              <Route path="/Setup/account-codes" element={<AccountCodes />} />
              <Route
                path="/Setup/service-company"
                element={<ServiceCompany />}
              />
              <Route path="/Setup/service-type" element={<ServiceType />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={["GOD"]} />}>
              <Route path="/all-company" element={<AllCompany />} />
            </Route>
          </Routes>
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/" element={<Landing />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="*"
              element={
                location.pathname === "/" ? null : <Navigate to="/" replace />
              }
            />
          </Routes>
        )}
      </div>
    </main>
  );
}

export default App;
