import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const tableFilterData = {
  company: {
    search: {},
    selects: [
      {},
      {
        name: "Status",
        value: "statuses",
        options: [
          {
            name: "Active",
            value: "active",
          },
          {
            name: "Inactive",
            value: "inactive",
          },
        ],
      },
    ],
  },
  customer: {
    search: {},
    selects: [
      {},
      {
        name: "Status",
        value: "statuses",
        options: [
          {
            name: "Pending",
            value: "pending",
          },
          {
            name: "Canceled",
            value: "canceled",
          },
          {
            name: "Approved",
            value: "approved",
          },
        ],
      },
    ],
  },
  invoice: {
    search: {
      options: [
        {
          name: "Phone",
          value: "phone",
        },
        {
          name: "ID",
          value: "ID",
        },
      ],
    },
    selects: [
      {},
      {
        name: "Status",
        value: "statuses",
        options: [
          {
            name: "Paid",
            value: "paid",
          },
          {
            name: "Canceled",
            value: "canceled",
          },
          {
            name: "Unpaid",
            value: "unpaid",
          },
        ],
      },
    ],
  },
  orders: {
    search: {},
    selects: [
      {},
      {
        name: "Status",
        value: "statuses",
        options: [
          {
            name: "Paid",
            value: "paid",
          },
          {
            name: "Canceled",
            value: "canceled",
          },
          {
            name: "Unpaid",
            value: "unpaid",
          },
        ],
      },
    ],
  },
  accountcode: {
    search: {},
    selects: [
      {},
      {
        name: "Parent",
        value: "parent",
        options: [
          {
            name: "No Parent",
            value: "noParent",
          },
          {
            name: "Has Parent",
            value: "hasParent",
          },
        ],
      },
    ],
  },
  serviceitem: {
    search: {},
    selects: [
      {},
      {
        name: "Status",
        value: "statuses",
        options: [
          {
            name: "Pending",
            value: "pending",
          },
          {
            name: "Canceled",
            value: "canceled",
          },
          {
            name: "Approved",
            value: "approved",
          },
        ],
      },
    ],
  },
};

// Get the width of the screen
const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

// Main hook function to get table parameters
export const useTableParameters = (name) => {
  const { t } = useTranslation(); // Get translation function from useTranslation
  let [width, setWidth] = useState(getWidth());
  const [mobileExpand, setMobileExpand] = useState(null);
  const role = useSelector((state) => state.user.roles[0]);

  const th = {
    company: [
      {
        name: "Company Name",
        width: 20,
        mobileWidth: 20,
        id: 0,
      },
      {
        name: "Email",
        width: 20,
        mobileWidth: 20,
        id: 1,
      },
      {
        name: "Phone Number",
        width: 20,
        mobileWidth: 20,
        id: 2,
      },
      {
        name: "Created Date",
        width: 20,
        mobileWidth: 20,
        id: 3,
      },
      {
        name: "Status",
        width: 20,
        mobileWidth: 20,
        id: 4,
      },
      {
        name: "",
        width: 1,
        mobileWidth: 1,
        id: 5,
      },
    ],
    customer: [
      {
        name: "Full Name",
        width: 15,
        mobileWidth: 16,
        id: 0,
      },
      {
        name: "Gender",
        width: 15,
        mobileWidth: 7,
        id: 1,
      },
      {
        name: "Phone Number",
        width: 15,
        mobileWidth: 20,
        id: 2,
      },
      {
        name: "Operator Name",
        width: 15,
        mobileWidth: 14,
        id: 3,
      },
      // {
      //   name: "Note",
      //   width: 14,
      //   mobileWidth: 14,
      //   id: 4,
      // },
      {
        name: "Status",
        width: 15,
        mobileWidth: 14,
        id: 4,
      },
      // {
      //   name: "Created Data",
      //   width: 14,
      //   mobileWidth: 14,
      //   id: 5,
      // },
      {
        name: "Bot",
        width: 10,
        mobileWidth: 14,
        id: 5,
      },
      {
        name: "Add Order",
        width: 15,
        mobileWidth: 14,
        id: 6,
      },
      {
        name: "Actions",
        width: 5,
        mobileWidth: 1,
        id: 7,
      },
    ],
    invoice: [
      {
        name: "Customer Name",
        width: 16,
        mobileWidth: 16,
        id: 0,
      },
      {
        name: "ID Number",
        width: 16,
        mobileWidth: 14,
        id: 1,
      },
      {
        name: "Operator",
        width: 16,
        mobileWidth: 12,
        id: 2,
      },
      {
        name: "Discount",
        width: 16,
        mobileWidth: 18,
        id: 3,
      },
      {
        name: "Status",
        width: 16,
        mobileWidth: 14,
        id: 4,
      },
      {
        name: "Price",
        width: 10,
        mobileWidth: 12,
        id: 5,
      },
      {
        name: "Actions",
        width: 5,
        mobileWidth: 1,
        id: 6,
      },
    ],
    users: [
      {
        name: "Email",
        width: 15,
        mobileWidth: 20,
        id: 0,
      },
      {
        name: "User Name",
        width: 16,
        mobileWidth: 20,
        id: 1,
      },
      {
        name: "Role",
        width: 16,
        mobileWidth: 15,
        id: 2,
      },
      {
        name: "User Id",
        width: 16,
        mobileWidth: 30,
        id: 3,
      },
      {
        name: "Last Login",
        width: 16,
        mobileWidth: 15,
        id: 4,
      },
      {
        name: "Actions",
        width: 5,
        mobileWidth: 1,
        id: 5,
      },
    ],
    expense: [
      {
        name: "Expense Category",
        width: 11,
        mobileWidth: 11,
        id: 0,
      },
      {
        name: "Code",
        width: 11,
        mobileWidth: 11,
        id: 1,
      },
      {
        name: "Description",
        width: 10,
        mobileWidth: 11,
        id: 2,
      },
      {
        name: "Document",
        width: 10,
        mobileWidth: 11,
        id: 3,
      },
      {
        name: "Payment Method",
        width: 11,
        mobileWidth: 11,
        id: 4,
      },
      {
        name: "Expense Amount",
        width: 8,
        mobileWidth: 8,
        id: 5,
      },
      {
        name: "Data",
        width: 11,
        mobileWidth: 11,
        id: 6,
      },
      {
        name: "Actions",
        width: 5,
        mobileWidth: 0.5,
        id: 7,
      },
    ],
    investment: [
      {
        name: "Investment Category",
        width: 10,
        mobileWidth: 11,
        id: 0,
      },
      {
        name: "Code",
        width: 10,
        mobileWidth: 11,
        id: 1,
      },
      {
        name: "Description",
        width: 10,
        mobileWidth: 11,
        id: 2,
      },
      {
        name: "Document",
        width: 11,
        mobileWidth: 11,
        id: 3,
      },
      {
        name: "Payment Method",
        width: 11,
        mobileWidth: 11,
        id: 4,
      },
      {
        name: "Investment Amount",
        width: 8,
        mobileWidth: 9.5,
        id: 5,
      },
      {
        name: "Data",
        width: 11,
        mobileWidth: 11,
        id: 6,
      },
      {
        name: "Actions",
        width: 5,
        mobileWidth: 0.5,
        id: 7,
      },
    ],

    serviceitem: [
      {
        name: "Service",
        width: 10,
        mobileWidth: 11,
        id: 0,
      },
      {
        name: "Data",
        width: 12,
        mobileWidth: 11,
        id: 1,
      },
      {
        name: "Destination",
        width: 14,
        mobileWidth: 11,
        id: 2,
      },
      {
        name: "Paid Amount",
        width: 8,
        mobileWidth: 11,
        id: 3,
      },
      {
        name: "Performance Status",
        width: 12,
        mobileWidth: 11,
        id: 4,
      },

      {
        name: "Status",
        width: 8,
        mobileWidth: 9.5,
        id: 5,
      },
      {
        name: "Service provider",
        width: 12,
        mobileWidth: 9.5,
        id: 6,
      },
      {
        name: "Customer Name",
        width: 11,
        mobileWidth: 0.5,
        id: 7,
      },
      {
        name: "Customer Phone Number",
        width: 12,
        mobileWidth: 0.5,
        id: 8,
      },
      {
        name: "Actions",
        width: 5,
        mobileWidth: 0.5,
        id: 9,
      },
    ],
    serviceType: [
      {
        name: "Name",
        width: 47,
        mobileWidth: 50,
        id: 0,
      },
      {
        name: "Logo",
        width: 47,
        mobileWidth: 50,
        id: 1,
      },
      {
        name: "Actions",
        width: 5,
        mobileWidth: 0.5,
        id: 2,
      },
    ],
    serviceCompany: [
      {
        name: "Name",
        width: 25,
        mobileWidth: 25,
        id: 0,
      },
      {
        name: "Bank Name",
        width: 24,
        mobileWidth: 24,
        id: 1,
      },
      {
        name: "Bank Account Number",
        width: 25,
        mobileWidth: 25,
        id: 2,
      },
      {
        name: "Type",
        width: 25,
        mobileWidth: 25,
        id: 3,
      },
      {
        name: "Actions",
        width: 5,
        mobileWidth: 1,
        id: 4,
      },
    ],
    expenseCategory: [
      {
        name: "Name",
        width: 47,
        mobileWidth: 50,
        id: 0,
      },
      {
        name: "Description",
        width: 47,
        mobileWidth: 50,
        id: 1,
      },
      {
        name: "Actions",
        width: 5,
        mobileWidth: 1,
        id: 2,
      },
    ],
    investmentCategory: [
      {
        name: "Name",
        width: 47,
        mobileWidth: 50,
        id: 0,
      },
      {
        name: "Description",
        width: 47,
        mobileWidth: 50,
        id: 1,
      },
      {
        name: "Actions",
        width: 5,
        mobileWidth: 1,
        id: 2,
      },
    ],
    accountcode: [
      {
        name: "Code",
        width: 47,
        mobileWidth: 30,
        id: 0,
      },
      {
        name: "Description",
        width: 47,
        mobileWidth: 50,
        id: 1,
      },
      {
        name: "Actions",
        width: 5,
        mobileWidth: 20,
        id: 2,
      },
    ],
    orders: [
      {
        name: "Customer Name",
        width: 14,
        mobileWidth: 16,
        id: 0,
      },
      {
        name: "Phone Number",
        width: 12,
        mobileWidth: 12,
        id: 1,
      },
      {
        name: "Operator",
        width: 12,
        mobileWidth: 14,
        id: 2,
      },
      {
        name: "Status",
        width: 10,
        mobileWidth: 12,
        id: 3,
      },
      {
        name: "Created Date",
        width: 11,
        mobileWidth: 14,
        id: 4,
      },
      {
        name: "Order Items",
        width: 13,
        mobileWidth: 12,
        id: 5,
      },
      {
        name: "Cost",
        width: 10,
        mobileWidth: 10,
        id: 6,
      },
      {
        name: "Generate Invoice",
        width: 11,
        mobileWidth: 12,
        id: 7,
      },
      {
        name: "Actions",
        width: 5,
        mobileWidth: 1,
        id: 8,
      },
    ],
  };

  // Update width on window resize
  useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  let mobile = false;
  if (width <= 1300) {
    mobile = true;
  }

  let mobileExpandFunc = (id) => {
    if (width <= 1300) {
      if (id !== mobileExpand) {
        setMobileExpand(id);
      } else {
        setMobileExpand(null);
      }
    }
  };

  const getTranslatedTh = (headers) => {
    return headers.map((header) => ({
      ...header,
      name: t(`${name}.${header.name}`, header.name),
    }));
  };

  const translateOptions = (selects) =>
    selects.map((select) => ({
      ...select,
      options: select?.options?.map((option) => ({
        ...option,
        name: t(`statuses.${option.name}`, option.name),
      })),
    }));

  if (name.toLowerCase() === "company") {
    return {
      tableFilterData: {
        ...tableFilterData.company,
        selects: translateOptions(tableFilterData.company.selects),
      },
      th: getTranslatedTh(th.company),
      mobileExpandFunc,
      mobileExpand,
      mobile,
    };
  }

  if (name.toLowerCase() === "customer") {
    return {
      tableFilterData: {
        ...tableFilterData.customer,
        selects: translateOptions(tableFilterData.customer.selects),
      },
      th: getTranslatedTh(th.customer),
      mobileExpandFunc,
      mobileExpand,
      mobile,
    };
  }

  if (name.toLowerCase() === "invoice") {
    return {
      tableFilterData: {
        ...tableFilterData.invoice,
        search: {
          ...tableFilterData.invoice.search,
          options: tableFilterData?.invoice?.search?.options?.map((option) => ({
            ...option,
            name: t(`searchOptions.${option.name}`),
          })),
        },
        selects: translateOptions(tableFilterData.invoice.selects),
      },
      th: getTranslatedTh(th.invoice),
      mobileExpandFunc,
      mobileExpand,
      mobile,
    };
  }

  if (name.toLowerCase() === "users") {
    return {
      th: getTranslatedTh(th.users),
      mobileExpandFunc,
      mobileExpand,
      mobile,
    };
  }

  if (name.toLowerCase() === "expense") {
    return {
      th: getTranslatedTh(th.expense),
      mobileExpandFunc,
      mobileExpand,
      mobile,
    };
  }

  if (name.toLowerCase() === "investment") {
    return {
      th: getTranslatedTh(th.investment),
      mobileExpandFunc,
      mobileExpand,
      mobile,
    };
  }

  if (name.toLowerCase() === "serviceitem") {
    return {
      tableFilterData: {
        ...tableFilterData.serviceitem,
        selects: translateOptions(tableFilterData.serviceitem.selects),
      },
      th: getTranslatedTh(th.serviceitem),
      mobileExpandFunc,
      mobileExpand,
      mobile,
    };
  }

  if (name.toLowerCase() === "servicetype") {
    return {
      th: getTranslatedTh(th.serviceType),
      mobileExpandFunc,
      mobileExpand,
      mobile,
    };
  }

  if (name.toLowerCase() === "servicecompany") {
    return {
      th: getTranslatedTh(th.serviceCompany),
      mobileExpandFunc,
      mobileExpand,
      mobile,
    };
  }

  if (name.toLowerCase() === "expensecategory") {
    return {
      th: getTranslatedTh(th.expenseCategory),
      mobileExpandFunc,
      mobileExpand,
      mobile,
    };
  }

  if (name.toLowerCase() === "investmentcategory") {
    return {
      th: getTranslatedTh(th.investmentCategory),
      mobileExpandFunc,
      mobileExpand,
      mobile,
    };
  }

  if (name.toLowerCase() === "accountcode") {
    return {
      tableFilterData: {
        ...tableFilterData.accountcode,
        selects: translateOptions(tableFilterData.accountcode.selects),
      },
      th: getTranslatedTh(th.accountcode),
      mobileExpandFunc,
      mobileExpand,
      mobile,
    };
  }

  if (name.toLowerCase() === "orders") {
    return {
      tableFilterData: {
        ...tableFilterData.orders,
        selects: translateOptions(tableFilterData?.orders?.selects),
      },
      th: getTranslatedTh(th?.orders),
      mobileExpandFunc,
      mobileExpand,
      mobile,
    };
  }
};
