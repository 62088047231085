import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useFetchOperators from "../../hooks/useFetchOperators";
import { useTableParameters } from "../../hooks/useTableParameters";
import { AdminPanel } from "../../UI/AdminPanel/AdminPanel";
import { Button } from "../../UI/Button/Button";
import { Input } from "../../UI/Input/Input";
import { Popup } from "../../UI/Popup/Popup";
import { AddSquareIcon } from "../../assets/svgs";

import InvoiceModal from "./invoiceModal/InvoiceModal";
import { useTranslation } from "react-i18next";
import { getOrderInputs } from "./inputs";
import ServiceTabs from "../ServiceTabs/ServiceTabs";
import PdfFile from "../PdfFolder/PdfFile";

import { useOrderContext } from "./OrderContext";
import OrderCard from "../../UI/OrderCard/OrderCard";

import "react-toastify/dist/ReactToastify.css";
import styles from "./Order.module.css";

const Order = () => {
  const {
    popupShow,
    setPopupShow,
    editService,
    setEditService,
    setAddService,
    addService,
    popUpData,
    setPopUpData,
    reload,
  } = useOrderContext();
  const { t } = useTranslation();
  const axios = useAxiosPrivate();
  const { tableFilterData } = useTableParameters("orders");
  const company_id = useSelector((state) => state.user.companyId);
  const role = useSelector((state) => state.user.roles[0]);
  const user_id = useSelector((state) => state.user.user);

  const { operators } = useFetchOperators(company_id);

  const [activeTab, setActiveTab] = useState("");
  const [serviceTypes, setServiceTypes] = useState([]);
  const [pageNow, setPageNow] = useState(1);
  const [pageAll, setPageAll] = useState(5);
  const [edit, setEdit] = useState(false);
  const [tableFilterOutcomingData, setTableFilterOutcomingData] = useState({
    selects: { statuses: "all" },
  });
  const [ordersData, setOrdersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [markedServices, setMarkedServices] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [serviceCompanies, setServiceCompanys] = useState([]);

  const BANK_ACCOUNTS = bankAccounts.map((account) => ({
    name: account.bank_name,
    value: {
      bank_name: account.bank_name,
      account_number: account.account_number,
    },
  }));

  const handleInputChange = (e, params) => {
    const { name, onChange } = params;

    let data;
    if (!e.target) {
      data = {
        target: {
          value: e,
          name,
        },
      };
      return onChange(data);
    }

    onChange(e);
  };

  const inputs = getOrderInputs(popUpData, setPopUpData, operators, t);

  const clearPopUpData = () => {
    setDisabledBtn(false);
    setPopUpData({
      _id: "",
      operator_id: "",
      full_name: "",
      phone_number: {
        code: "+995",
        flag: "🇬🇪",
        number: "",
      },
      national_ID_number: "",
      payment_method: "",

      // Consistent date fields
      start_date: "",
      end_date: "",

      // Location and Destination for different services
      location: "",
      destination: "",

      detales: "",
      note: "",
      total_price: 0,
      discount: "",
      passenger: "",
      services: [],
      passengers: [],

      // Specific fields for certain services
      hotel_name: "", // For hotel-related services
      visa_type: "", // For visa-related services
      invitation_type: "", // For invitation-related services
      insurance_provider: "", // For insurance-related services
      zone: "", // For insurance-specific field

      // Baggage-related fields
      baggage_type: "",
      baggage_weight: "",
    });
    setAddService({
      _id: "",
      start_date: "",
      end_date: "",
      location: "",
      destination: "",
      invoice_pdf: "",
      amount: 0,
      document: "",
      service_type_id: "",
      service_company_id: "",
      company_id: company_id,
      operator_id: user_id,
    });
    setEditService({
      _id: "",
      start_date: "",
      end_date: "",
      location: "",
      destination: "",
      invoice_pdf: "",
      amount: 0,
      document: "",
      service_type_id: "",
      service_company_id: "",
      company_id: company_id,
      operator_id: user_id,
    });

    setActiveTab("");
    setMarkedServices("");
    setEdit(false);
    setCurrentStep(1);
  };

  useEffect(() => {
    getCompanyBankAccounts();
    getServiceTypes();
    getServiceCompanys();
  }, []);

  const getOrders = async () => {
    // if (!tableFilterOutcomingData?.search?.value) return;
    setIsLoading(true);
    if (role === "OPERATOR") {
      try {
        const res = await axios.post("/order/get-all-users-orders", {
          company_id,
          user_id,
          page: pageNow,
          tableFilterOutcomingData,
          limit: 10,
        });
        setOrdersData(res.data.orders);
        setPageAll(res.data.totalPages);
      } catch (err) {
        toast(err.response?.data?.message || err.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        const res = await axios.post("/order/get-all-orders", {
          company_id,
          page: pageNow,
          tableFilterOutcomingData,
          limit: 10,
        });
        setOrdersData(res.data.orders);
        setPageAll(res.data.totalPages);
      } catch (err) {
        toast(err.response?.data?.message || err.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getCompanyBankAccounts = async () => {
    try {
      const res = await axios.post("/company/get-company-by-id", {
        company_id,
      });
      setBankAccounts(res.data.companies.payment_methods);
    } catch (err) {
      toast(err.response?.data?.message || err.message);
    }
  };

  const getServiceTypes = async () => {
    try {
      const res = await axios.post("/service-type/get-all-service-types", {
        company_id,
      });
      setServiceTypes(res.data.serviceTypes);
    } catch (err) {
      toast(err.response?.data?.message || err.message);
    }
  };

  const getServiceCompanys = async () => {
    const transformData = (data) => {
      return data?.map((item) => ({
        name: item.name,
        value: item._id,
      }));
    };
    try {
      const res = await axios.post(
        "/service-company/get-all-service-companies",
        {
          company_id,
        }
      );

      setServiceCompanys(transformData(res.data.serviceCompanies));
    } catch (err) {
      toast(err.response?.data?.message || err.message);
    }
  };

  useEffect(() => {
    if (
      !tableFilterOutcomingData?.search?.value ||
      tableFilterOutcomingData.search.value.length >= 3
    ) {
      getOrders();
    }
  }, [pageNow, tableFilterOutcomingData, reload]);

  const submitHandler = async () => {
    setDisabledBtn(true);
    try {
      const services = popUpData.services.map((service) => {
        let mappedService = {
          service_type_id: service.type,
          location: service.location || "",
          destination: service.destination || "",
          start_date: service.start_date || "",
          end_date: service.end_date || "",
        };

        if (service.serviceName === "Visa") {
          mappedService = {
            ...mappedService,
            visa_type: service.visa_type || "",
          };
        } else if (service.serviceName === "Hotel") {
          mappedService = {
            ...mappedService,
            hotel_name: service.hotel_name || "",
          };
        } else if (service.serviceName === "Invitation") {
          mappedService = {
            ...mappedService,
            invitation_type: service.invitation_type || "",
          };
        } else if (service.serviceName === "Insurance") {
          mappedService = {
            ...mappedService,
            insurance_provider: service.insurance_provider || "",
            zone: service.zone || "",
          };
        } else if (service.serviceName === "Baggage") {
          mappedService = {
            ...mappedService,
            baggage_type: service.baggage_type || "",
            baggage_weight: service.baggage_weight || "",
          };
        }

        return mappedService;
      });

      const payload = {
        _id: popUpData._id,
        customer_id: popUpData.customer_id || undefined,
        newCustomer: popUpData.customer_id
          ? undefined
          : {
              full_name: popUpData.full_name,
              phone_number: popUpData.phone_number,
              national_ID_number: popUpData.national_ID_number,
            },
        note: popUpData.note,
        payment_method: {
          bank_name: popUpData.payment_method.bank_name,
          account_number: popUpData.payment_method.bank_account_numbr,
        },
        status: "unpaid",
        passengers: popUpData.passengers || ["Default Passenger"],
        services,
        operator_id: popUpData.operator_id,
        company_id: company_id,
      };

      const endpoint = edit ? "/order/edit-order" : "/order/add-new-order";
      const res = await axios.post(endpoint, payload);

      clearPopUpData();
      getOrders();
      toast(res.data.message);
      setPopupShow(null);
      setEdit(false);
      setDisabledBtn(false);
      setCurrentStep(1);
    } catch (err) {
      toast(err.response?.data?.message || err.message);
      setDisabledBtn(false);
    }
  };

  const deleteOrder = async (orderId) => {
    try {
      const res = await axios.post(`/order/delete-order`, {
        _id: orderId,
      });
      toast(res.data.message);
      getOrders();
    } catch (err) {
      toast(err.response?.data?.message || err.message);
    }
  };

  const addPassenger = () => {
    setPopUpData((prev) => ({
      ...prev,
      passengers: [...prev.passengers, popUpData.passenger],
    }));

    setPopUpData((prev) => ({
      ...prev,
      passenger: "",
    }));
  };

  const removePassenger = (index) => {
    const newValuesArray = popUpData.passengers.filter((_, i) => i !== index);
    setPopUpData((prev) => ({
      ...prev,
      passengers: newValuesArray,
    }));
  };

  const changeOrderStatus = async (orderId, status) => {
    try {
      const res = await axios.post("/order/change-order-pay-status", {
        _id: orderId,
        status,
      });
      getOrders();
      toast(res.data.message);
    } catch (error) {
      toast.error("Error changing order status");
    }
  };

  const editServiceHandler = async () => {
    await axios
      .post("/service-item/edit-service", editService)
      .then((res) => {
        clearPopUpData();
        getOrders();
        toast(res.data.message);
        setPopupShow(null);
      })
      .catch((err) => {
        err.response.data.message && toast(err.response.data.message);
        err.message && toast(err.message);
      });
  };

  const addServiceHandler = async () => {
    await axios
      .post("/order/add-new-service", addService)
      .then((res) => {
        clearPopUpData();
        toast(res.data.message);
        setPopupShow(null);
        getOrders();
      })
      .catch((err) => {
        err.response.data.message && toast(err.response.data.message);
        err.message && toast(err.message);
      });
  };

  const srviceInputs = [
    {
      title: t("serviceItemInputTitles.serviceCompany"),
      name: "service_company_id",
      type: "lable-input-select",
      options: serviceCompanies,
      defaultAny: t("serviceItemInputTitles.serviceCompanyPlaceholder"),
      required: true,
      onChange: (e) =>
        setEditService((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
    {
      title: t("serviceitem.Paid Amount"),
      name: "amount",
      type: "number",
      placeholder: t("serviceItemInputTitles.amountPlaceholder"),
      value: editService.amount,
      required: true,
      onChange: (e) =>
        setEditService((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
  ];

  const transformData = (data) => {
    return data?.map((item) => ({
      name: item.name,
      value: item._id,
    }));
  };

  const addServiceInputs = [
    {
      title: t("serviceItemInputTitles.serviceType"),
      name: "service_type_id",
      type: "lable-input-select",
      options: transformData(serviceTypes),
      defaultAny: t("serviceItemInputTitles.serviceTypePlaceholder"),
      required: true,
      value: addService?.service_type_id || "",
      onChange: (e) =>
        setAddService((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
    {
      title: t("serviceItemInputTitles.serviceCompany"),
      name: "service_company_id",
      type: "lable-input-select",
      options: serviceCompanies,
      defaultAny: t("serviceItemInputTitles.serviceCompanyPlaceholder"),
      required: true,
      value: addService.service_company_id || "",
      onChange: (e) =>
        setAddService((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
    {
      title: t("serviceItemInputTitles.location"),
      name: "location",
      type: "default",
      placeholder: t("serviceItemInputTitles.locationPlaceholder"),
      value: addService.location,
      onChange: (e) =>
        setAddService((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
    {
      title: t("serviceItemInputTitles.destination"),
      name: "destination",
      type: "default",
      placeholder: t("serviceItemInputTitles.destinationPlaceholder"),
      value: addService.destination,
      onChange: (e) =>
        setAddService((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
    {
      title: t("serviceItemInputTitles.date"),
      name: "start_date",
      type: "date-picker-input",
      placeholder: t("serviceItemInputTitles.datePlaceholder"),
      value: addService.start_date,
      onChange: (e) =>
        setAddService((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
    {
      title: t("serviceItemInputTitles.dateOfEnd"),
      name: "end_date",
      type: "date-picker-input",
      placeholder: t("serviceItemInputTitles.dateOfEndPlaceholder"),
      value: addService.end_date,
      onChange: (e) =>
        setAddService((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
    {
      title: t("serviceItemInputTitles.amount"),
      name: "amount",
      type: "number",
      placeholder: t("serviceItemInputTitles.amountPlaceholder"),
      value: addService.amount,
      required: true,
      onChange: (e) =>
        setAddService((prev) => ({
          ...prev,
          [e.target.name]: e.target.value,
        })),
    },
  ];

  let ordercards;
  ordercards = ordersData.map((order, index) => (
    <OrderCard
      key={index}
      index={index}
      order={order}
      changeOrderStatus={changeOrderStatus}
      deleteOrder={deleteOrder}
    />
  ));

  return (
    <>
      <AdminPanel
        adminPage={"table"}
        tableData={<>{ordercards}</>}
        pageLabel={t("order")}
        dataLoading={isLoading}
        tableEmulator={false}
        tableHeader={1}
        tableFilter={true}
        tableFilterData={tableFilterData}
        setTableFilterOutcomingData={setTableFilterOutcomingData}
        tableFilterOutcomingData={tableFilterOutcomingData}
        paginationCurrent={pageNow}
        paginationTotal={pageAll}
        paginationEvent={(page) => setPageNow(page)}
        searchLabel={t("order")}
        tableHeaderButtons={
          <Button
            label={t("addOrder")}
            size={"btn-lg"}
            type={"btn-primary"}
            element={"button"}
            svg={<AddSquareIcon />}
            onClick={() => setPopupShow("Add Order")}
          />
        }
      />
      {popupShow === "Add Order" && (
        <Popup
          label={popupShow}
          handlePopUpClose={() => {
            clearPopUpData();
            setPopupShow(null);
          }}
          customStyles={{ maxWidth: "95%", minWidth: "50%" }}
          popUpElement={
            <div className="popupElementContent">
              {currentStep === 1 && (
                <>
                  <div className={styles.gg}>
                    <h2 className={styles.title}>
                      {t("orders.enterCustomerInfo")}
                    </h2>
                    <div className={styles.descriptionWrapper}>
                      <span className={styles.clickEmoji} aria-hidden="true">
                        👇
                      </span>
                      <p className={styles.description}>
                        {t("orders.enter_customer_info_first")}
                      </p>
                    </div>
                  </div>

                  <div className="pdf-inputs">
                    {inputs.map((params, index) => (
                      <div className="exchange-input-wrapper" key={index}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span className="inputLabelColumn">
                            {params?.title}
                          </span>
                          {params.required && (
                            <span style={{ color: "red", marginLeft: "10px" }}>
                              *
                            </span>
                          )}
                        </div>
                        <Input
                          key={index}
                          type={params.type}
                          // label={params.title}
                          defaultData={params.options}
                          value={params.value || ""}
                          name={params.name}
                          customStyles={{ width: "100%" }}
                          selectHandler={(opt) =>
                            handleInputChange(opt, params)
                          }
                          selectLabel={"select"}
                          placeholder={params.placeholder}
                          onChange={(e) => handleInputChange(e, params)}
                          editable={edit}
                          arrayTitle={params.arrayTitle}
                          handleAddValue={addPassenger}
                          handleDeleteValue={removePassenger}
                          valueArray={popUpData.passengers}
                          required={params.required}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="popup-buttons-wrapper">
                    <Button
                      label={t("clearForm")}
                      type={"btn-secondary"}
                      element={"button"}
                      onClick={clearPopUpData}
                      size={"btn-lg"}
                      customStyles={{ width: "40%" }}
                      disabled={disabledBtn}
                    />
                    <Button
                      label={t("orders.nextStep")}
                      type={"btn-primary"}
                      element={"button"}
                      onClick={() => setCurrentStep(2)}
                      size={"btn-lg"}
                      customStyles={{ width: "40%" }}
                      disabled={disabledBtn}
                    />
                  </div>
                </>
              )}

              {currentStep === 2 && (
                <>
                  <ServiceTabs
                    setPopUpData={setPopUpData}
                    edit={edit}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    markedServices={markedServices}
                    setMarkedServices={setMarkedServices}
                    handleInputChange={handleInputChange}
                    disabledBtn={disabledBtn}
                    popUpData={popUpData}
                    serviceTypes={serviceTypes}
                  />
                  <div className="popup-buttons-wrapper">
                    <Button
                      label={t("orders.back")}
                      type={"btn-secondary"}
                      element={"button"}
                      onClick={() => setCurrentStep(1)}
                      size={"btn-lg"}
                      customStyles={{ width: "40%" }}
                      disabled={disabledBtn}
                    />
                    <Button
                      label={t("addOrder")}
                      type={"btn-primary"}
                      element={"button"}
                      onClick={submitHandler}
                      size={"btn-lg"}
                      customStyles={{ width: "40%" }}
                      svg={<AddSquareIcon />}
                      disabled={disabledBtn}
                    />
                  </div>
                </>
              )}
            </div>
          }
        />
      )}
      {popupShow === "Generate Invoice" && (
        <Popup
          label={"Generate Invoice"}
          customStyles={{ minWidth: "60%" }}
          handlePopUpClose={() => {
            clearPopUpData();
            setPopupShow(null);
          }}
          popUpElement={<InvoiceModal bankAccounts={BANK_ACCOUNTS} />}
        />
      )}
      {popupShow === "PDF" && (
        <Popup
          label={"Invoice"}
          handlePopUpClose={() => {
            clearPopUpData();
            setPopupShow(null);
          }}
          customStyles={{ width: "auto" }}
          popUpElement={
            <div className="invoice-popup-wrapper">
              <PdfFile popUpData={popUpData} />
            </div>
          }
        />
      )}
      {popupShow === "Edit Service" && (
        <Popup
          label={t("editService")}
          handlePopUpClose={() => {
            setPopupShow(null);
            clearPopUpData();
          }}
          customStyles={{ maxWidth: "95%", minWidth: "50%" }}
          // description={"rame"}
          popUpElement={
            <div className="popupElementContent">
              <div className="pdf-inputs">
                {srviceInputs?.map((params, index) => {
                  return (
                    <div className="exchange-input-wrapper" key={index}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span className="inputLabelColumn">
                          {params?.title}
                        </span>
                        {params.required && (
                          <span style={{ color: "red", marginLeft: "10px" }}>
                            *
                          </span>
                        )}
                      </div>
                      <Input
                        key={index}
                        type={params?.type}
                        label={params?.title}
                        defaultData={params?.options}
                        value={params.value || ""}
                        name={params.name}
                        customStyles={{ width: "100%" }}
                        selectHandler={(opt) => {
                          handleInputChange(opt, params);
                        }}
                        selectLabel={"select"}
                        placeholder={params?.placeholder}
                        onChange={(e) => handleInputChange(e, params)}
                        customInputStyles={{
                          border: "1px solid rgba(255, 255, 255, 0.1)",
                        }}
                        required={params?.required}
                        editable={edit}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="popup-buttons-wrapper">
                <Button
                  label={t("clearForm")}
                  type={"btn-secondary"}
                  element={"button"}
                  onClick={clearPopUpData}
                  size={"btn-lg"}
                  customStyles={{
                    width: "40%",
                  }}
                  disabled={disabledBtn}
                />
                <Button
                  label={t("editService")}
                  type={"btn-primary"}
                  element={"button"}
                  onClick={editServiceHandler}
                  size={"btn-lg"}
                  customStyles={{
                    width: "40%",
                  }}
                  svg={<AddSquareIcon />}
                  disabled={disabledBtn}
                />
              </div>
            </div>
          }
        />
      )}
      {popupShow === "add service" && (
        <Popup
          label={t("addService")}
          inputs={inputs}
          handlePopUpClose={() => {
            setPopupShow(null);
            clearPopUpData();
          }}
          popUpData={popUpData}
          setPopUpData={setPopUpData}
          customStyles={{ minWidth: "50%", maxWidth: "95%" }}
          // description={"rame"}
          popUpElement={
            <div className="popupElementContent">
              <div className="pdf-inputs">
                {addServiceInputs?.map((params, index) => {
                  return (
                    <div className="exchange-input-wrapper" key={index}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span className="inputLabelColumn">
                          {params?.title}
                        </span>
                        {params.required && (
                          <span style={{ color: "red", marginLeft: "10px" }}>
                            *
                          </span>
                        )}
                      </div>
                      <Input
                        key={index}
                        type={params?.type}
                        // label={params?.title}
                        defaultData={params?.options}
                        value={params.value || ""}
                        name={params.name}
                        customStyles={{ width: "100%" }}
                        selectHandler={(opt) => {
                          handleInputChange(opt, params);
                        }}
                        selectLabel={"select"}
                        placeholder={params?.placeholder}
                        onChange={(e) => handleInputChange(e, params)}
                        customInputStyles={{
                          border: "1px solid rgba(255, 255, 255, 0.1)",
                        }}
                        required={params?.required}
                        editable={edit}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="popup-buttons-wrapper">
                <Button
                  label={t("clearForm")}
                  type={"btn-secondary"}
                  element={"button"}
                  onClick={clearPopUpData}
                  size={"btn-lg"}
                  customStyles={{
                    width: "40%",
                  }}
                  disabled={disabledBtn}
                />
                <Button
                  label={t("addService")}
                  type={"btn-primary"}
                  element={"button"}
                  onClick={addServiceHandler}
                  size={"btn-lg"}
                  customStyles={{
                    width: "40%",
                  }}
                  svg={<AddSquareIcon />}
                  disabled={disabledBtn}
                />
              </div>
            </div>
          }
        />
      )}
      <ToastContainer theme="dark" />
    </>
  );
};

export default Order;
