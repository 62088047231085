// src/hooks/useAxiosPrivate.js

import { useEffect } from "react";
import axiosPrivate from "../api/axiosPrivate";
import useRefreshToken from "./useRefreshToken";
import { useSelector, useDispatch } from "react-redux";
import { logOut } from "../store/userReducer ";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const useAxiosPrivate = () => {
  const access_token = useSelector((state) => state.user.access_token);
  const refresh = useRefreshToken();
  const dispatch = useDispatch();

  useEffect(() => {
    const reqInterceptor = axiosPrivate.interceptors.request.use(
      (request) => {
        if (!request.headers["authorization"]) {
          request.headers["authorization"] = `Bearer ${access_token}`;
        }
        return request;
      },
      (error) => Promise.reject(error)
    );

    const resInterceptor = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;

        // Determine if the request was made to /login or /refresh
        const isAuthRoute =
          prevRequest?.url.includes("/auth/login") ||
          prevRequest?.url.includes("/auth/refresh");

        if (
          (error?.response?.status === 401 ||
            error?.response?.status === 403) &&
          !prevRequest?.sent &&
          !isAuthRoute
        ) {
          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                prevRequest.headers["authorization"] = "Bearer " + token;
                return axiosPrivate(prevRequest);
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }

          prevRequest.sent = true;
          isRefreshing = true;

          return new Promise(async (resolve, reject) => {
            try {
              const newAccessToken = await refresh();
              axiosPrivate.defaults.headers.common["Authorization"] =
                "Bearer " + newAccessToken;
              prevRequest.headers["authorization"] = "Bearer " + newAccessToken;
              processQueue(null, newAccessToken);
              resolve(axiosPrivate(prevRequest));
            } catch (err) {
              processQueue(err, null);
              dispatch(logOut());
              reject(err);
            } finally {
              isRefreshing = false;
            }
          });
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(reqInterceptor);
      axiosPrivate.interceptors.response.eject(resInterceptor);
    };
  }, [access_token, refresh, dispatch]);

  return axiosPrivate;
};

export default useAxiosPrivate;
