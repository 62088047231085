import React, { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";

const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
  const company_id = useSelector((state) => state.user.companyId);
  const user_id = useSelector((state) => state.user.user);
  const [popupShow, setPopupShow] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [reload, setReload] = useState(false);
  const [editService, setEditService] = useState({
    _id: "",
    start_date: "",
    end_date: "",
    location: "",
    destination: "",
    invoice_pdf: "",
    amount: 0,
    document: "",
    service_type_id: "",
    service_company_id: "",
    company_id: company_id,
    operator_id: user_id,
  });
  const [addService, setAddService] = useState({
    _id: "",
    start_date: "",
    end_date: "",
    location: "",
    destination: "",
    invoice_pdf: "",
    amount: 0,
    document: "",
    service_type_id: "",
    service_company_id: "",
    company_id: company_id,
    operator_id: user_id,
  });

  const [popUpData, setPopUpData] = useState({
    _id: "",
    operator_id: user_id,
    full_name: "",
    phone_number: {
      code: "+995",
      flag: "🇬🇪",
      number: "",
    },
    national_ID_number: "",
    payment_method: "",

    // Consistent date fields
    start_date: "",
    end_date: "",

    // Location and Destination for different services
    location: "",
    destination: "",

    detales: "",
    note: "",
    total_price: 0,
    discount: "",
    passenger: "",
    services: [],
    passengers: [],

    // Specific fields for certain services
    hotel_name: "", // For hotel-related services
    visa_type: "", // For visa-related services
    invitation_type: "", // For invitation-related services
    insurance_provider: "", // For insurance-related services
    zone: "", // For insurance-specific field

    // Baggage-related fields
    baggage_type: "",
    baggage_weight: "",
  });

  const value = {
    popupShow,
    setPopupShow,
    editService,
    setEditService,
    addService,
    setAddService,
    popUpData,
    setPopUpData,
    selectedOrder,
    setSelectedOrder,
    reload,
    setReload,
  };

  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
};

export const useOrderContext = () => {
  return useContext(OrderContext);
};
