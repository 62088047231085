import axios from "../api/axiosPrivate";
import { setNewAccessToken, logOut } from "../store/userReducer ";
import { useDispatch } from "react-redux";

const useRefreshToken = () => {
  const dispatch = useDispatch();

  const refresh = async () => {
    try {
      const { data } = await axios.get("/refresh");
      dispatch(
        setNewAccessToken({
          access_token: data.access_token,
        })
      );

      return data.access_token;
    } catch (err) {
      console.error("Error refreshing token:", err);
      dispatch(logOut());
      throw err;
    }
  };

  return refresh;
};

export default useRefreshToken;
