import React from "react";

const EditIcon = ({ onClick }) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M1.24989 17.1252C1.16169 17.1257 1.07438 17.1076 0.993696 17.072C0.91301 17.0364 0.840777 16.9841 0.781743 16.9185C0.72271 16.853 0.678213 16.7757 0.651179 16.6918C0.624144 16.6078 0.615185 16.5191 0.624888 16.4314L1.10614 12.0564C1.12327 11.9166 1.18714 11.7867 1.28739 11.6877L11.5124 1.46269C11.6982 1.27655 11.9188 1.12887 12.1617 1.02811C12.4046 0.927352 12.665 0.875488 12.928 0.875488C13.191 0.875488 13.4514 0.927352 13.6943 1.02811C13.9372 1.12887 14.1579 1.27655 14.3436 1.46269L16.2874 3.40644C16.4735 3.59221 16.6212 3.81286 16.722 4.05577C16.8227 4.29868 16.8746 4.55908 16.8746 4.82206C16.8746 5.08504 16.8227 5.34544 16.722 5.58835C16.6212 5.83126 16.4735 6.05192 16.2874 6.23769L6.06864 16.4564C5.96966 16.5567 5.83972 16.6206 5.69989 16.6377L1.32489 17.1189L1.24989 17.1252ZM2.33114 12.4064L1.95614 15.7939L5.34364 15.4189L15.4061 5.35644C15.4761 5.28676 15.5315 5.20396 15.5694 5.1128C15.6072 5.02164 15.6267 4.9239 15.6267 4.82519C15.6267 4.72648 15.6072 4.62874 15.5694 4.53757C15.5315 4.44641 15.4761 4.36362 15.4061 4.29394L13.4561 2.34394C13.3865 2.27402 13.3037 2.21854 13.2125 2.18069C13.1213 2.14283 13.0236 2.12335 12.9249 2.12335C12.8262 2.12335 12.7284 2.14283 12.6373 2.18069C12.5461 2.21854 12.4633 2.27402 12.3936 2.34394L2.33114 12.4064Z"
        fill="#0884FF"
      />
      <path
        d="M14.375 7.88166C14.2928 7.88213 14.2112 7.86637 14.1351 7.83527C14.0589 7.80416 13.9897 7.75833 13.9313 7.70041L10.05 3.80666C9.99174 3.74838 9.94552 3.6792 9.91398 3.60306C9.88244 3.52693 9.86621 3.44532 9.86621 3.36291C9.86621 3.2805 9.88244 3.19889 9.91398 3.12275C9.94552 3.04661 9.99174 2.97743 10.05 2.91916C10.1083 2.86088 10.1775 2.81466 10.2536 2.78312C10.3298 2.75158 10.4114 2.73535 10.4938 2.73535C10.5762 2.73535 10.6578 2.75158 10.7339 2.78312C10.8101 2.81466 10.8792 2.86088 10.9375 2.91916L14.8313 6.81291C14.8898 6.87101 14.9363 6.94014 14.9681 7.0163C14.9998 7.09246 15.0161 7.17415 15.0161 7.25666C15.0161 7.33917 14.9998 7.42086 14.9681 7.49702C14.9363 7.57318 14.8898 7.64231 14.8313 7.70041C14.7714 7.75985 14.7001 7.80656 14.6217 7.83771C14.5433 7.86886 14.4594 7.88381 14.375 7.88166Z"
        fill="#0884FF"
      />
      <path
        d="M10.0533 6.81488L5.1875 11.6807L6.07138 12.5645L10.9372 7.69877L10.0533 6.81488Z"
        fill="#0884FF"
      />
    </svg>
  );
};

export default EditIcon;
